const MessagesDataModule = {
  firestorePath: "/projects/{projectId}/messages",
  firestoreRefType: "collection", 
  moduleName: "MessagesDataModule",
  statePropName: "messages",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%",
    },
  },

  state: {},
  getters: {
    messages(state) {
      return state.messages;
       
    },
  },
  mutations: {},
  actions: {},
  sync: {
    preventInitialDocInsertion: true,
  },
};

export default MessagesDataModule;
