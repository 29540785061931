<template>
  <div
    class=""
    style="text-align: center;position: absolute;top:50px;width: 100%;  border-radius: 10px;height: -webkit-fill-available; "
    :style="{ background: colors.messageList.bg }"
  >
    <div
      class="close-form"
      v-on:click.stop.prevent="hidden = true"
      @click="$emit('exitForm')"
    >
      <img src="!!url-loader!@/assets/close.svg" alt="" />
    </div>
    <h3 class="row">
      {{ form.title }}
    </h3>
    <p class="row">
      {{ form.subtitle }}
    </p>
    <div class="row form">
      <form action="" @submit.prevent="sendForm()">
        <div v-for="element in form.fields" :key="element.name" class="row">
          <label style="font-size: 13px" v-if="element.link"
            ><a :href="element.link">{{ element.label }}</a></label
          >
          <label style="font-size: 13px" v-else>{{ element.label }}</label>

          <input
            v-if="element.type != 'textarea'"
            v-bind="element"
            class="form--text"
            v-model="element.value"
          />

          <textarea
            v-if="element.type == 'textarea'"
            maxlength="200"
            v-bind="element"
            v-model="element.value"
          ></textarea>
        </div>
        <div class="row">
          <input
            type="submit"
            style="float: right"
            :style="{ 'background-color': colors.sentMessage.bg }"
            value="Enviar"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CloseIconSvg from "!!url-loader!@/assets/close.svg";

export default {
  name: "FormMessage",
  components: {},
  props: {
    form: {
      type: Object,
      default: () => [],
    },
    colors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      closeIcon: CloseIconSvg,
    };
  },
  methods: {
    sendForm() {
      let form = { event: this.form.onsubmit };
      this.form.fields.forEach((element) => {
        if (element.value) {
          form[element.name] = element.value;
        }
      });
      this.$emit("submitForm", form);
    },
  },
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.close-form {
  height: 10px;
  width: 10px;
  position: relative;
}
input,
select,
textarea {
  width: 97%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  resize: none;
}
input[type="checkbox"] {
  width: 10px;
  cursor: pointer;
  color: transparent;
}

label {
  padding: 12px 12px 0px 0;
  display: inline-block;
}
.row {
  margin-top: 5px;
  margin-bottom: 5px;
}
input[type="submit"] {
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 5px;
}
input[type="cancel"] {
  color: rgb(26, 23, 23);
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  float: left;
  margin: 5px;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
.form {
  text-align: start;
  min-height: 55px;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  justify-content: center;
}

.form--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  filter: contrast(15%);
  outline: none;
  cursor: text;
}
</style>
