var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"carousel-view"},[_c('div',{staticStyle:{"position":"absolute","top":"50%","right":"0px","margin":"0","/* position":"absolute","*/\n        /* top":"50%","*/\n        flex-direction":"row","-ms-transform":"translateY(-50%)","transform":"translateY(-50%)","z-index":"1","height":"40px"}},[_c('img',{attrs:{"height":"40px","src":_vm.rightbtn},on:{"click":function($event){return _vm.next()}}})]),_c('div',{staticStyle:{"position":"absolute","transform":"translateY(-50%) rotate(180deg)","top":"50%","left":"0px","margin":"0","/* position":"absolute","*/\n        /* top":"50%","*/\n        flex-direction":"row","-ms-transform":"translateY(-50%) rotate(180deg)","z-index":"1","height":"40px"}},[_c('img',{attrs:{"height":"40px","src":_vm.rightbtn},on:{"click":function($event){return _vm.previous()}}})]),_c('transition-group',{staticClass:"carousel",attrs:{"name":"fade","tag":"div"}},_vm._l((_vm.cards.slice(0, 3)),function(card,index){return _c('div',{key:index,staticClass:"slide slide-fade"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"card",class:{ active: index == 1 },style:({
              border: index == 1
                ? '2px solid ' + _vm.LightenDarkenColor(_vm.backgroundColor, 40)
                : 'none',
            }),on:{"click":function($event){index == 1 ? _vm.onMessageWasSent(card.payload||card.text) : _vm.clicked(index)}}},[(card.url)?_c('img',{staticClass:"sc-image",attrs:{"src":card.url}}):_vm._e(),_c('div',{staticClass:"card-text"},[_c('button',{staticClass:"title sc-suggestions-element",style:({
                  color: 'rgb(64 64 64)'
                    ? '#222222'
                    : '#fff',
                }),on:{"click":function($event){index != 1 ? _vm.onMessageWasSent(card.payload||card.text) : ''}}},[_vm._v(" "+_vm._s(card.text)+" ")]),(card.description)?_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(card.description)+" ")]):_vm._e()])])])],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }