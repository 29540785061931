const sharedDataModule = {
  firestorePath: "/projects/{projectId}/widgets/default",
  firestoreRefType: "doc",
  moduleName: "widgetModule",
  statePropName: "widget",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%",
    },
  },

  state: {},
  getters: {
    widget(state) {
      return state.widget
       
    },
  },
  mutations: {},
  actions: {},
  sync: {
    preventInitialDocInsertion: true,
  },
};

export default sharedDataModule;
