<template>
  <div>
    <button class="sc-user-input--button-icon-wrapper" :style="{ background: colors.bg, color: colors.text }">
      <IconBase
        width="25"
        height="25"
        view-box="0 0 24 24"
        :icon-name="tooltip"
      >
        <slot />
      </IconBase>
  </button>
  </div>
</template>

<script>
import IconBase from './components/IconBase.vue'

export default {
  components: {
    IconBase
  },
  props: {
    colors: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
  .sc-user-input--button-icon-wrapper {
    border: none;
    padding: 0px;
    margin: 0px;
    outline: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    padding-right: 2px;
    box-shadow: 0px 0px 3px 1px #bbb;
  }
  @media only screen and (max-width: 480px) {
    .sc-user-input--button-icon-wrapper{
      width: 60px;
      height: 60px;
    }
  }
</style>
