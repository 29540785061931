<template>
  <div class="" style="">
    <div class="carousel-view">
      <div
        style="
          position: absolute;
          top: 50%;
          right: 0px;
          margin: 0;
          /* position: absolute; */
          /* top: 50%; */
          flex-direction: row;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          z-index: 1;
          height: 40px;
        "
      >
        <img height="40px" @click="next()" :src="rightbtn" />
      </div>
      <div
        style="
          position: absolute;
          transform: rotate(180deg);

          top: 50%;
          left: 0px;
          margin: 0;
          /* position: absolute; */
          /* top: 50%; */
          flex-direction: row;
          -ms-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
          z-index: 1;
          height: 40px;
        "
      >
        <img height="40px" @click="previous()" :src="rightbtn" />
      </div>
      <transition-group class="carousel" name="fade" tag="div">
        <div
          v-for="(card, index) in cards.slice(0, 3)"
          class="slide slide-fade"
          :key="index"
        >
          <transition name="fade">
            <div
              class="card"
              
              :class="{ active: index == 1 }"
              @click="index == 1 ? onMessageWasSent(card.payload||card.text) : clicked(index)"
              :style="{
                border: index == 1
                  ? '2px solid ' + LightenDarkenColor(backgroundColor, 40)
                  : 'none',
              }"
            >
              <img :src="card.url" v-if="card.url" class="sc-image" />
              <div class="card-text">
                <button
                  class="title sc-suggestions-element"
                  @click="index != 1 ? onMessageWasSent(card.payload||card.text) : ''"
                  :style="{
                    color: 'rgb(64 64 64)'
                      ? '#222222'
                      : '#fff',
                  }"
                >
                  {{ card.text }}
                </button>
                <p v-if="card.description" class="description">
                  {{ card.description }}
                </p>
              </div>
            </div>
          </transition>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import Right from "!!url-loader!@/assets/circled-chevron-right.png";

export default {
  name:"CarrouselMessage",
  methods: {
    is_light(color) {
      if (color) {
        const hex = color.replace("#", "");
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
        return brightness > 155;
      } else {
        return false;
      }
    },
    LightenDarkenColor(col, amt) {
      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
    clicked(index) {
      if (index == 0) {
        this.previous();
      } else if (index == 2) {
        this.next();
      }
    },
    next() {
      this.moving = true;
      const first = this.cards.shift();
      this.data.carrousel = this.cards.concat(first);
      this.moving = false;
    },
    previous() {
      this.moving = true;
      const last = this.cards.pop();
      this.data.carrousel = [last].concat(this.cards);
      this.moving = false;
    },
    onMessageWasSent(message) {
      this.$store.dispatch("chatModule/set", {
        type: "text",
        data: { text: message },
      });
    },
  },
  data() {
    return {
      rightbtn: Right,
      moving: false,
      name: "default",
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },

    messageColors: {
      type: Object,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
  },
  computed: {
    cards() {
      let cards = this.data.carrousel;
      if (cards.length < 3 && !this.moving) {
        cards = cards.concat(this.data.carrousel);
        if (cards.length < 3) {
          return cards.concat(this.data.carrousel);
        }
      }

      return cards;
    },
  },
};
</script>

<style scoped>
.sc-image {
  width: 100%;
  height: 80px;
  object-fit: contain;
  padding-top: 12px;
}

.card {
  box-shadow: 0 0px 4px 2px rgb(0 0 0 / 20%);
  transition: 0.3s;
  border-radius: 6px; /* 5px rounded corners */
  text-align: center;
}

.title {
  overflow-wrap: anywhere;
  font-weight: 600;
  font-size: 14px;
  margin: 5px;
  border: none;
}
.description {
  margin: 5px;
  font-size: 11px;
  font-weight: 200;
}
.card.active {
  transition: 0.3s;
  border-radius: 5px; 
  font-weight: bold;
  text-align: center;
}

/* Add rounded corners to the top left and the top right corner of the image */
img {
  border-radius: 5px 5px 0 0;
}

.carousel-view {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  line-height: 1.4;
  -webkit-font-smoothing: subpixel-antialiased;
  place-content: center;
}
.carousel {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide {
  flex: 0 0 50%;
  /* height: 10em; */
  margin: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.6s;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide:first-of-type {
  opacity: 0.7;
}
.slide:last-of-type {
  opacity: 0.7;
}
</style>
