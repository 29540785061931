<template>
  <div>
    <div
      v-if="tooltip.title || tooltip.subtitle"
      v-on:click.prevent
      class="message-tooltip"
      :class="{ 'left-tooltip': !left, 'right-tooltip': left, hidden: hidden }"
    >
      <h3>{{ tooltip.title }}</h3>
      <p>{{ tooltip.subtitle }}</p>
      <i></i>
      <div class="close-tooltip" v-on:click.stop.prevent="hidden = true">
        <img src="!!url-loader!@/assets/close.svg" alt="" />
      </div>
    </div>
    <!-- <div style="    visibility: hidden;
">{{tooltip}}</div> -->
  </div>
</template>

<script>
import CloseIconSvg from "!!url-loader!@/assets/close.svg";

export default {
  name:"tooltipMessage",
  data() {
    return { hidden: false ,
    closeIcon: CloseIconSvg};
  },
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    tooltip: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none !important;
}
.close-tooltip {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position: absolute;
  border: 1px solid #aaa;
  align-items: center;
  justify-content: center;
  top: -6px;
  left: -5px;
  background: #fff;
  display: flex;
  visibility: hidden;
}
.close-tooltip img {
  width: 7px;
  position: relative;
  top: -0.5px;
  left: -0.5px;
}
.right-tooltip {
  left: 80px;

  margin-left: 20px;
}
.message-tooltip {
  min-width: 200px;
  max-width: 400px;
  transform: translate(0%, -100%);
  padding: 5px 10px;
  color: #666666;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 0 2px 1px #ccc;
  text-align: center;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  top: 45px;
}
.message-tooltip:hover .close-tooltip {
  visibility: visible;
}
.message-tooltip h3,
.message-tooltip p {
  margin: 0;
  width: 100%;
  font-weight: 600;
}

.right-tooltip i {
  position: absolute;
  top: 70%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.message-tooltip i::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  border: 1px solid #ddd;
  background-color: #fff;
}

.left-tooltip {
  right: 60px;
  margin-right: 20px;
}

.left-tooltip i {
  position: absolute;
  left: 100%;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.left-tooltip i::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
</style>