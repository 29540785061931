<template>
  <div
    class="sc-chat-window"
    :class="{
      opened: isOpen,
      closed: !isOpen,
      right: !left,
      left: left,
      keyboard: keyboard,
      fullscreen: fullscreen,
    }"
    :style="{
      left: left ? parseInt(marginx) + 'px' : 'initial',
      right: !left ? parseInt(marginx) + 'px' : 'initial',
      bottom: `${parseInt(marginy) + 75}px`,
    }"
  >
    <Header
      v-if="showHeader"
      :title="title"
      :colors="colors"
      @close="$emit('close')"
      @userList="handleUserListToggle"
    >
      <template>
        <slot name="header"> </slot>
      </template>
    </Header>
    <UserList
      v-if="showUserList"
      :colors="colors"
      :participants="participants"
    />
    <MessageList
      v-if="!showUserList"
      :messages="messages"
      :backgroundImageWidget="backgroundImageWidget"
      :participants="participants"
      :show-typing-indicator="showTypingIndicator"
      :colors="colors"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :message-styling="messageStyling"
      @scrollToTop="$emit('scrollToTop')"
      @remove="$emit('remove', $event)"
      :titleHeader="title"
    >
      <template v-slot:user-avatar="scopedProps">
        <slot
          name="user-avatar"
          :user="scopedProps.user"
          :message="scopedProps.message"
        >
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot
          name="text-message-body"
          :message="scopedProps.message"
          :messageText="scopedProps.messageText"
          :messageColors="scopedProps.messageColors"
          :me="scopedProps.me"
        >
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message"> </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot
          name="text-message-toolbox"
          :message="scopedProps.message"
          :me="scopedProps.me"
        >
        </slot>
      </template>
    </MessageList>
    <UserInput
      v-if="!showUserList"
      :show-emoji="showEmoji"
      :on-submit="onUserInputSubmit"
      :suggestions="getSuggestions()"
      :form="getForm()"
      :show-file="showFile"
      :placeholder="placeholder"
      :colors="colors"
      @onType="$emit('onType')"
      @edit="$emit('edit', $event)"
      @onInputActive="changeHeight($event)"
    />
    <div v-if="!whiteLabel" style="text-align: center">
      <div v-if="isDemo && false" style="margin: 10px 0">
        <a
          class="content_link_brand"
          @click="$emit('close')"
          style="margin-right: 30px;"
        >
          <img :src="logoFooter" />
        </a>
        <a
          @click="$emit('close')"
          style="cursor: pointer; color: #000;"
          class="content_link_brand"
        >
          <!-- subrayado -->
          <span style="text-decoration: bold; font-weight: bold;">
            {{ messageFoot }}</span
          >
        </a>
      </div>
      <div v-else style="margin: 0px 0 0">
        <a class="content_link_brand" href="https://lovi.ai" target="_blank">
          <img :src="logoFooter" style="height: 30px; padding: 0 10px" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import logoFooter from "!!url-loader!@/assets/logoBotslovers.png";
import logoFooter from "!!url-loader!@/assets/logofooter.svg";
import Header from "./Header.vue";
import MessageList from "./MessageList.vue";
import UserInput from "./UserInput.vue";
import UserList from "./UserList.vue";

export default {
  name: "ChatWindow",
  components: {
    Header,
    MessageList,
    UserInput,
    UserList,
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    marginx: {
      type: [Number, String],
      default: 20
    },
    marginy: {
      type: [Number, String],
      default: 20
    },
    whiteLabel: {
      type: Boolean,
      default: false,
    },
    showEmoji: {
      type: Boolean,
      default: false,
    },
    showFile: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    participants: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onUserInputSubmit: {
      type: Function,
      required: true,
    },
    messageList: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    showTypingIndicator: {
      type: String,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true,
    },
    messageStyling: {
      type: Boolean,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundImageWidget: {
      type: String,
      required: false,
    },
  },
  mounted() {
    this.setPlatformInfo();
  },
  data() {
    return {
      showUserList: false,
      logoFooter,
      keyboard: false,
      isSafari: false,
    };
  },
  computed: {
    messages() {
      let messages = this.messageList;

      return messages;
    },
    getCurrentLang() {
      return this.$store.state.currentLang;
    },
    messageFoot() {
      let placeholders = {
        es: "Quiero este bot para mi web",
        en: "I want this bot for my website",
      };
      return placeholders[this.getCurrentLang] || placeholders.es;
    },
  },
  methods: {
    setPlatformInfo() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") != -1) {
        // if is mobile
        if (ua.indexOf("mobile") != -1) {
          if (ua.indexOf("chrome") > -1) {
            this.isSafari = false;
          } else {
            this.isSafari = true;
          }
        }
      }
    },
    changeHeight(isActive) {
      // change height of chat window
      this.setPlatformInfo();
      if (this.isSafari) {
        if (isActive) {
          this.keyboard = true;
          // scroll to top
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 200);
        } else {
          this.keyboard = false;
        }
      }
    },
    handleUserListToggle(showUserList) {
      this.showUserList = showUserList;
    },
    getSuggestions() {
      if (
        this.messages[this.messages.length - 1] &&
        this.messages[this.messages.length - 1].data
      )
        return this.messages[this.messages.length - 1].data.suggestions || [];
    },
    getForm() {
      if (
        this.messages[this.messages.length - 1] &&
        this.messages[this.messages.length - 1].data &&
        this.messages[this.messages.length - 1].data.type === "form"
      )
        console.log(this.messages[this.messages.length - 1].data);
      return this.messages[this.messages.length - 1]
        ? this.messages[this.messages.length - 1].data
        : null;
    },
  },
};
</script>

<style scoped>
.keyboard {
  height: calc(100% - 300px) !important;
}
.fullscreen {
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  right: 0px;
  bottom: 0px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
}
.sc-chat-window {
  z-index: 2147483647;
  width: 370px;
  height: calc(100% - 120px);
  max-height: 590px;
  position: fixed;
  bottom: 100px;
  box-sizing: border-box;
  -webkit-box-shadow: rgb(0 0 0 / 23%) 0 4px 14px;
  box-shadow: rgb(0 18 46 / 16%) 0px 8px 36px 0px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}
.right {
  right: 25px;
}
.left {
  left: 25px;
}
.sc-chat-window.closed {
  opacity: 0;
  display: none;
  bottom: 90px;
}
.content_link_brand {
  padding-bottom: 0;
}
.content_link_brand img {
  height: 15px;
}
@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

.sc-message--me {
  text-align: right;
}
.sc-message--them {
  text-align: left;
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
    inset: 0px !important;
  }
  .sc-chat-window {
    transition: 0.1s ease-in-out;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}
</style>
