<template>
  <div>
    <div v-if="live || idle" class="sc-message--text" :style="messageColors">
      <div style="color: grey">
        <VueMarkdown
          v-if="idle != ''"
          class="sc-message--text-content"
          style="font-style: italic; font-size: 12px"
          :style="messageColors"
          :source="idle"
          :show="true"
        />
        <VueMarkdown
          v-if="live != ''"
          class="sc-message--text-content"
          style="font-size: 14px"
          :source="live"
          :show="true"
        />

        <!-- {{ text }} -->
      </div>
    </div>
    <!-- on message change, make transition -->
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { FirebaseDatabase } from "@/config/firebase.js";
export default {
  components: {
    VueMarkdown,
  },
  props: {
    messageColors: {
      type: Object,
      required: true,
    },
  },
  // show messages if the time is more than 3 seconds

  data() {
    return {
      live: "",
      idle: "",
      connect: function() {},
    };
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    message() {
      return this.message;
    },
  },
  watch: {
    live() {
      this.$emit("updated");
    },
    idle() {
      this.$emit("updated");
    },
  },
  // mounted() {
  //   this.sync();
  // },
  methods: {
    sync() {
      try {
        let key =
          "/live/" + window.loviproject + "/" + window.loviuser + "/message";
        let ref = FirebaseDatabase.ref(key);
        ref
          .once("value")
          .then((snapshot) => {
            if (snapshot.exists()) {
              // La clave existe, realiza las acciones necesarias con los datos
              this.connect = FirebaseDatabase.ref(key).on(
                "value",
                (snapshot) => {
                  if (!snapshot.exists()) {
                    this.live = "";
                    this.idle = "";
                  }
                  try {
                    if (typeof snapshot.val() === "object") {
                      if (snapshot.val().idle) {
                        this.idle = snapshot.val().idle;
                      } else {
                        this.idle = "";
                      }
                      if (snapshot.val().live) {
                        this.live = snapshot.val().live;
                      } else {
                        this.live = "";
                      }
                    } else {
                      this.live = "";
                      this.idle = "";
                    }
                  } catch (error) {
                    this.live = "";
                    this.idle = "";
                  }
                }
              );
            } else {
              // La clave no existe
              // Espera 1 segundo y luego vuelve a llamar a la función
              // FirebaseDatabase.ref(key).set("");
              // console.log("no existe");
            }
          })
          .catch((error) => {
            error;
            // console.error(error);
            setTimeout(() => {
              this.sync();
            }, 3000);
          });
      } catch (error) {
        setTimeout(() => {
          this.sync();
        }, 3000);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.sync();
    }, 3000);
  },
  // unmounted() {

  // },
  style: {
    color: "white",
  },
};
</script>

<style scoped lang="scss">
// animation: slideIn 0.2s ease-out 0s 1 normal forwards running;

.sc-message--text {
  box-shadow: 0 4px 16px 0 #0000001a;
  font-size: 12px;
  line-height: 1.3;
  position: relative;
  border-radius: 20px 20px 20px 0;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 80%;
  padding: 1px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: all 0.5s ease-out 0s;
  .sc-message--text-content {
    white-space: pre-wrap;
  }
  &:hover .sc-message--toolbox {
    left: -20px;
    opacity: 1;
  }
  .sc-message--toolbox {
    transition: left 0.2s ease-out 0s;
    white-space: normal;
    opacity: 0;
    position: absolute;
    left: 0px;
    width: 25px;
    top: 0;
    button {
      background: none;
      border: none;
      padding: 0px;
      margin: 0px;
      outline: none;
      width: 100%;
      text-align: center;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    ::deep svg {
      margin-left: 5px;
    }
  }
  code {
    font-family: "Courier New", Courier, monospace !important;
  }
}
</style>
