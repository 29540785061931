<template>
  <div class="" style="margin: 10px; text-align: center" v-if="privacy.active">
    <div class="row privacy" :style="{ background: colors.messageList.bg }">
      <form action="" @submit.prevent="sendprivacy()">
        <div class="row">
          <div v-html="privacy.text" style="font-size: 13px" ref="privacy" />
        </div>
        <div class="row">
          <input
            type="submit"
            style="float: right"
            :style="{
              'background-color': colors.sentMessage.bg,
              color: colors.sentMessage.text,
            }"
            :value="privacy.button"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CloseIconSvg from "!!url-loader!@/assets/close.svg";
// import vLinkify from "vue-linkify-v2";
export default {
  name: "PrivacyMessage",
  components: {
    // vLinkify,
  },
  props: {
    colors: {
      type: Object,
      required: true,
    },
    privacy: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      closeIcon: CloseIconSvg,
    };
  },

  methods: {
    sendprivacy() {
      this.$emit("aceptPrivacy", this.privacy);
    },
  },

  onMounted() {
    const privacyLinks = this.$refs.privacy.querySelectorAll("a");
    console.log(privacyLinks);
    privacyLinks.forEach((element) => {
      element.style.color = this.color.header.launcher.bg;
    });
  },
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.close-privacy {
  height: 10px;
  width: 10px;
  position: relative;
}
input,
select,
textarea {
  width: 97%;

  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  resize: vertical;
}

label {
  padding: 12px 12px 0px 0;
  display: inline-block;
}
.row {
  margin-top: 5px;
  margin-bottom: 5px;
}
input[type="submit"] {
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 5px;
}
input[type="cancel"] {
  color: rgb(26, 23, 23);
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  float: left;
  margin: 5px;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
.privacy {
  text-align: start;
  min-height: 55px;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  justify-content: center;
}

.privacy--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  filter: contrast(15%);
  outline: none;
  cursor: text;
}
</style>
