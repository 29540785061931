import Vue from "vue";
import Vuex from "vuex";
import VuexEasyFirestore from "vuex-easy-firestore";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import Firebase from "../config/firebase.js";
import chatModule from "./modules/chatModule.js";
import sharedDataModule from "./modules/sharedDataModule.js";
import widgetModule from "./modules/widgetModule.js";
import userModule from "./modules/userModule.js";
import messagesModule from "./modules/messagesModule.js";

const easyFirestore = VuexEasyFirestore(
  [chatModule, widgetModule, sharedDataModule, userModule, messagesModule],
  {
    logging: false,
    FirebaseDependency: Firebase
  }
);

const store = new Vuex.Store({
  state: {
    currentLang: "es",
    projectId: "",
    LoviUserId: ""
  },
  mutations: {
    setState(state, data) {
      this.data;
      Vue.set(state, data.key, data.value);
    },
    editMessage(state, message) {
      this.editMessage = message;
    },
    setCurrentLang(state, lang) {
      state.currentLang = lang;
    }
  },

  actions: {
    setState({ commit }, data) {
      commit("setState", data);
    }
  },
  modules: {},
  plugins: [
    easyFirestore,
    createPersistedState({
      key: "Lovi",
      paths: ["LoviUserId"]
    })
  ]
});
export default store;

/*
 * Use store pattern instead of Vuex since this is a plugin
 * and instantiated externally
 **/

// const store = {
//   state: Vue.observable({
//     editMessage: null,
//   }),

// setState(key, val) {
//   Vue.set(this.state, key, val);
// },
// };

// function mapState(keys) {
//   const map = {};
//   keys.forEach((key) => {
//     map[key] = function () {
//       return store.state[key];
//     };
//   });
//   return map;
// }
