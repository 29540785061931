<template>
  <div class="sc-message--text" :style="messageColors">
    <template>
      <div
        class="sc-message--toolbox"
        :style="{ background: messageColors.backgroundColor }"
      >
        <slot name="text-message-toolbox" :message="message"> </slot>

        <!-- <button class="sc-suggestions-element" style="background-color: #f5f5f5; border: 1px solid #e0e0e0; border-radius: 4px; box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); color: #333; cursor: pointer; display: inline-block; font-size: 12px; font-weight: 400; line-height: 1.42857143; margin-bottom: 0; padding: 6px 12px; text-align: center; vertical-align: middle; white-space: nowrap;" onclick="window.open('https://newsroom.porsche.com/es<u>ES/producto/2022/es-porsche-taycan-sport-turismo-gts-turbo-4s-27065.html', '</u>blank')">Nuevo Taycan Sport T</button> -->
      </div>
    </template>

    <slot :message="message" :messageColors="messageColors">
      <p class="sc-message--text-content">{{ message.text }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    messageColors: {
      type: Object,
      required: true,
    },
    botName: {
      type: String,
      required: false,
      default: "Lovi",
    },
  },
  // show messages if the time is more than 3 seconds

  data() {
    return {
      message: {
        show: false,
        text: "",
      },
    };
  },
  mounted() {
    this.message.text = this.messageList[0];
    this.message.show = true;
    this.interval = setInterval(() => {
      this.message.text = this.messageList[
        // rotate through the messages
        (this.messageList.indexOf(this.message.text) + 1) %
          this.messageList.length
      ];
      clearInterval(this.interval2);

      this.message.show = false;
      this.message.show = true;
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    messageList() {
      return [
        "Hello! I'm " + this.botName + ", how can I help you?",
        "Hola! Soy " + this.botName + ". ¿En qué puedo ayudarte?",
        "Bonjour! Je suis " + this.botName + ", comment puis-je vous aider?",
        "Hallo! Ich bin " + this.botName + ", wie kann ich Ihnen helfen?",
        "Ciao! Sono " + this.botName + ", come posso aiutarti?",
        "Olá! Sou " + this.botName + ", como posso ajudar?",
        "Cześć! Jestem " + this.botName + ", w czym mogę pomóc?",
        "こんにちは！私は" + this.botName + "です。どうすればいいですか？",
        "안녕하세요! 저는 " + this.botName + "입니다. 어떻게 도와드릴까요?",
        "สวัสดี! ฉันคือ " + this.botName + " ฉันสามารถช่วยคุณได้อย่างไร",
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.sc-message--text {
  padding: 5px 20px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  max-width: 85%;
  height: 55px;

  -webkit-font-smoothing: subpixel-antialiased;
  .sc-message--text-content {
    white-space: pre-wrap;
  }
  &:hover .sc-message--toolbox {
    left: -20px;
    opacity: 1;
  }
  .sc-message--toolbox {
    transition: left 0.2s ease-out 0s;
    white-space: normal;
    opacity: 0;
    position: absolute;
    left: 0px;
    width: 25px;
    top: 0;
    button {
      background: none;
      border: none;
      padding: 0px;
      margin: 0px;
      outline: none;
      width: 100%;
      text-align: center;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    ::deep svg {
      margin-left: 5px;
    }
  }
  code {
    font-family: "Courier New", Courier, monospace !important;
  }
}

.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #4e8cff;
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}
.sc-message--text-content a {
  font-weight: 600;
  color: black;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
  margin-right: 40px;
}

a.chatLink {
  color: inherit !important;
}
.sc-suggestions-row {
  /* display: inline-grid; */
  width: 100%;
  text-align: center;
  background: inherit;
}

.sc-suggestions-element {
  margin: 3px;
  width: fit-content;
  /* justify-self: right; */

  padding: 5px 10px 5px 10px;
  border: 1px solid;
  border-radius: 15px;
  font-size: 14px;
  background: inherit;
  cursor: pointer;
}

// more links button
// must be sutil to not disturb the user and not be too intrusive but visible in a button over border bottom of the message
.sc-more-links {
  position: absolute;
  right: -7px;
  bottom: -9px;
  margin: 0;
  padding: 0;
  /* border: none; */
  background: white;
  border-radius: 50%;
  font-size: 12px;
  color: #999;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

  &:hover {
    color: #333;
    border-color: #adadad;
  }
}

.sc-link-button {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  color: #333;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  margin: 3px;
  width: -webkit-fill-available;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sc-link-button:hover {
  border-color: #adadad;
  color: #333;
}

.sc-link-row {
  width: --webkit-fill-available;
  text-align: center;
  background: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.hidden {
  display: none;
}
</style>
