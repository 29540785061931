const chatModule = {
  firestorePath: "/projects/{projectId}/users/{user}/chat",
  firestoreRefType: "collection",
  moduleName: "chatModule",
  statePropName: "messageList",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%",
    },
  },
  sync: {
    guard: ["created_by", "created_at"],
    defaultValues: {
      created_via: "web",
      created_by: undefined,
      is_user: true,
      at_url: window.location.href || ""
    },
  },
  state: {},
  getters: {
    messages(state) {
      let messageList = Object.values(state.messageList).sort(function(a, b) {
        if (!a.created_at || !b.created_at) {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 1);
          if (!a.created_at) {
            a.created_at = tomorrow;
          }else{
            b.created_at = tomorrow;
          }
        }
        return new Date(a.created_at) - new Date(b.created_at);
      });
      return messageList;
    },
  },
  mutations: {},
  actions: {},
};

export default chatModule;
