<template>
  <div class="lang-menu" v-if="langs && langs.length > 1">
    <div class="selected-lang">
      <img
        :src="flags[currentLang || defaultCurrentLanguage].flag"
        style="    object-fit: contain;"
        alt=""
      />
    </div>

    <ul>
      <li v-for="(lang, index) in langs" :key="index">
        <span @click.prevent="changeLang(lang)">
          <img
            :src="flags[lang].flag"
            alt=""
            style="width: 20px; height: 20px;    object-fit: contain;
"
          />

          <!-- centered lang name -->

          <p class="lang-name">{{ flags[lang].name }}</p>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LangSelector",
  //   props: {
  //       langs: {
  //             type: Array,
  //             required: true
  //         }
  //     },
  data() {
    return {
      flags: {
        es: {
          name: "Español",
          flag: require("!!url-loader!@/assets/images/flags/es.png"),
        },
        gl: {
          name: "Galego",
          //asset2/s/images/flags/gl.png
          flag: require("!!url-loader!@/assets/images/flags/gl.svg"),
        },
        ca: {
          name: "Català",
          flag: require("!!url-loader!@/assets/images/flags/ca.png"),
        },
        va: {
          name: "Valencià",
          flag: require("!!url-loader!@/assets/images/flags/va.png"),
        },
        eu: {
          name: "Euskara",
          flag: require("!!url-loader!@/assets/images/flags/eu.png"),
        },
        en: {
          name: "English",
          flag: "https://www.countryflags.io/gb/flat/32.png",
        },
        fr: {
          name: "Français",
          flag: "https://www.countryflags.io/fr/flat/32.png",
        },
        de: {
          name: "Deutsch",
          flag: "https://www.countryflags.io/de/flat/32.png",
        },
        it: {
          name: "Italiano",
          flag: "https://www.countryflags.io/it/flat/32.png",
        },
        pt: {
          name: "Português",
          flag: "https://www.countryflags.io/pt/flat/32.png",
        },
        ru: {
          name: "Русский",
          flag: "https://www.countryflags.io/ru/flat/32.png",
        },
        zh: {
          name: "中文",
          flag: "https://www.countryflags.io/cn/flat/32.png",
        },
        ja: {
          name: "日本語",
          flag: "https://www.countryflags.io/jp/flat/32.png",
        },
        ko: {
          name: "한국어",
          flag: "https://www.countryflags.io/kr/flat/32.png",
        },
        ar: {
          name: "العربية",
          flag: "https://www.countryflags.io/sa/flat/32.png",
        },
        he: {
          name: "עברית",
          flag: "https://www.countryflags.io/il/flat/32.png",
        },
        tr: {
          name: "Türkçe",
          flag: "https://www.countryflags.io/tr/flat/32.png",
        },
        id: {
          name: "Bahasa Indonesia",
          flag: "https://www.countryflags.io/id/flat/32.png",
        },
      },
      currentLang: "",
    };
  },
  // props: {
  //   langs: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  methods: {
    changeLang(lang) {
      this.defaultCurrentLanguage = lang;
      this.currentLang = lang;
      this.$store.commit("setCurrentLang", lang);
      this.$emit("changeLang", lang);
      document.cookie = `widgetLang=${lang}`;
      this.$store.dispatch("chatModule/set", {
        type: "event",
        data: {event: "welcome", changeLang: lang },
      });
    },
  },
  computed: {
    defaultCurrentLanguage: {
      get: function() {
        if (document.cookie.indexOf("widgetLang") > -1) {
          return document.cookie.split("widgetLang=")[1].split(";")[0];
        }
        if (navigator.languages && navigator.languages.length) {
          for (let i = 0; i < navigator.languages.length; i++) {
            if (this.langs.indexOf(navigator.languages[i]) > -1) {
              return navigator.languages[i];
            }
          }
        }
        return this.$store.state.widgetModule.widget.languages[0];
        // return "es";
      },
      set: function(newLang) {
        document.cookie = `widgetLang=${newLang}`;
        //get the current language
        this.currentLanguage = newLang;
      },
    },
    langs() {
      return this.$store.state.widgetModule.widget.languages;
    },
  },
};
</script>

<style scoped>
/* Language selector */

.lang-menu .selected-lang .lang-name {
  /* margin-left: 10px; */
  font-size: 14px;
  font-weight: 600;
}
.lang-menu .selected-lang img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.lang-menu ul {
  position: absolute;
  top: 100%;
  left: 0;
  right: 40px;
  z-index: 1;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  background-color: #fff;
  color: #000;
  margin-top: 10px;
  width: fit-content;
}
.lang-menu ul li {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
.lang-menu ul li:hover {
  background: #f5f5f5;
}
.lang-menu ul li:hover ul {
  display: block;
}
.lang-menu ul li span {
  display: flex;
  align-items: center;
}
.lang-menu ul li span img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-left: 10px;
}
.lang-menu ul li span .lang-name {
  font-size: 14px;
  font-weight: 600;
}

.lang-menu {
  position: relative;
  margin-right: 10px;
  z-index: 3;

  display: flex;
  align-items: center;
}
.lang-menu .selected-lang {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 3px;
  cursor: pointer;
}

/* 

.lang-menu {
  width: 100px;
  text-align: right;
  font-weight: bold;
  position: relative;

  align-self: center;
  z-index: 1;
} */
.lang-menu .selected-lang {
  display: flex;
  justify-content: space-between;
  line-height: 2;
  cursor: pointer;
}
.lang-menu .selected-lang:before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  /* background-image: url(https://www.countryflags.io/us/flat/32.png); */
  background-size: contain;
  background-repeat: no-repeat;
}

.lang-menu ul {
  margin: 0;
  padding: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #f8f8f8;
  position: absolute;
  /* top: 45px; */
  right: 0px;
  /* width: 125px; */
  border-radius: 5px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.lang-menu ul li {
  list-style: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.lang-menu ul li a {
  text-decoration: none;
  width: 125px;
  padding: 5px 10px;
  display: block;
}

.lang-menu ul li:hover {
  background-color: #f2f2f2;
}

.lang-menu ul li a:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.de:before {
  background-image: url(https://www.countryflags.io/de/flat/32.png);
}

.en:before {
  background-image: url(https://www.countryflags.io/us/flat/32.png);
}
.fr:before {
  background-image: url(https://www.countryflags.io/fr/flat/32.png);
}

.ar:before {
  background-image: url(https://www.countryflags.io/ae/flat/32.png);
}

.lang-menu:hover ul {
  display: block;
}
</style>
