var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scrollList",staticClass:"sc-message-list",style:({
    backgroundColor: _vm.colors.messageList.bg,
    backgroundImage: ("url(" + _vm.backgroundImageWidget + ")"),
  }),on:{"scroll":_vm.handleScroll}},[(_vm.messages.length === 0 && !_vm.widget.welcome)?_c('Message',{attrs:{"message":{ created_by: _vm.showTypingIndicator, type: 'welcome' },"user":_vm.profile(_vm.showTypingIndicator),"colors":_vm.colors,"message-styling":_vm.messageStyling,"botName":_vm.titleHeader}}):_vm._e(),(_vm.messages.length === 0 && _vm.widget.welcome)?_c('Message',{attrs:{"message":{
      created_by: _vm.showTypingIndicator,
      type: 'text',
      data: { text: _vm.widget.welcome },
    },"user":_vm.profile(_vm.showTypingIndicator),"colors":_vm.colors,"message-styling":_vm.messageStyling,"botName":_vm.titleHeader}}):_vm._e(),_vm._l((_vm.messages),function(message,idx){return _c('Message',{key:idx,attrs:{"message":message,"user":_vm.profile(message.created_by),"colors":_vm.colors,"message-styling":_vm.messageStyling,"isLastMessage":_vm.messages[_vm.messages.length - 1] == message},on:{"remove":function($event){return _vm.$emit('remove', message)}},scopedSlots:_vm._u([{key:"user-avatar",fn:function(scopedProps){return [_vm._t("user-avatar",null,{"user":scopedProps.user,"message":scopedProps.message})]}},{key:"text-message-body",fn:function(scopedProps){return [_vm._t("text-message-body",null,{"message":scopedProps.message,"messageText":scopedProps.messageText,"messageColors":scopedProps.messageColors,"me":scopedProps.me})]}},{key:"system-message-body",fn:function(scopedProps){return [_vm._t("system-message-body",null,{"message":scopedProps.message})]}},{key:"text-message-toolbox",fn:function(scopedProps){return [_vm._t("text-message-toolbox",null,{"message":scopedProps.message,"me":scopedProps.me})]}}],null,true)})}),_c('Message',{attrs:{"message":{ created_by: _vm.showTypingIndicator, type: 'live' },"user":_vm.profile(_vm.showTypingIndicator),"colors":_vm.colors,"message-styling":_vm.messageStyling},on:{"updated":_vm.scrollToBottomToLive}}),(_vm.isTiping)?_c('Message',{attrs:{"message":{ created_by: _vm.showTypingIndicator, type: 'typing' },"user":_vm.profile(_vm.showTypingIndicator),"colors":_vm.colors,"message-styling":_vm.messageStyling}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }