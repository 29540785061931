var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showLauncher)?_c('div',{staticClass:"sc-launcher",class:{ opened: _vm.isOpen, right: !_vm.positionLeft, left: _vm.positionLeft },style:({
      backgroundColor: _vm.isOpen ? _vm.colors.launcher.bg : 'transparent',
      color: _vm.colors.launcher.bg,
      left:  _vm.positionLeft ? _vm.positionMarginX + 'px' : 'initial',
      right: !_vm.positionLeft ? _vm.positionMarginX + 'px' : 'initial',
      bottom: _vm.positionMarginY + 'px',
    }),on:{"click":function($event){$event.preventDefault();_vm.isOpen ? _vm.close() : _vm.openAndFocus()}}},[(_vm.showLauncher && !_vm.isOpen)?_c('tooltipMessage',{attrs:{"tooltip":_vm.tooltip,"left":_vm.positionLeft,"color":_vm.colors.launcher.bg},on:{"close":function($event){_vm.tooltip.show = _vm.closeTooltip}}}):_vm._e(),(_vm.newMessagesCount > 0 && !_vm.isOpen)?_c('div',{staticClass:"sc-new-messsages-count"},[_vm._v(" "+_vm._s(_vm.newMessagesCount)+" ")]):_vm._e(),(_vm.isOpen)?_c('img',{class:{
        'sc-closed-icon': true
      },style:({
        filter: _vm.is_light(_vm.colors.launcher.bg) ? '' : 'invert(100%)',
        bottom: _vm.positionMarginY + 'px'
      }),attrs:{"src":_vm.icons.close.img,"alt":_vm.icons.close.name}}):_c('img',{class:{
        'sc-open-icon': true,
      },style:({
        bottom: _vm.positionMarginY + 'px'
      }),attrs:{"src":_vm.titleImageUrl,"alt":_vm.icons.open.name}})],1):_vm._e(),_c('ChatWindow',{attrs:{"whiteLabel":_vm.whiteLabel,"left":_vm.positionLeft,"marginx":_vm.positionMarginX || 20,"marginy":_vm.positionMarginY || 20,"backgroundImageWidget":_vm.backgroundImageWidget,"message-list":_vm.messageList,"on-user-input-submit":_vm.onMessageWasSent,"participants":_vm.participants,"title":_vm.chatWindowTitle,"is-open":_vm.isOpen,"show-emoji":_vm.showEmoji,"show-file":_vm.showFile,"show-header":_vm.showHeader,"placeholder":_vm.placeholder,"show-typing-indicator":_vm.showTypingIndicator,"colors":_vm.colors,"always-scroll-to-bottom":_vm.alwaysScrollToBottom,"message-styling":_vm.messageStyling,"isDemo":_vm.isDemo,"fullscreen":_vm.fullScreenMode},on:{"close":_vm.close,"scrollToTop":function($event){return _vm.$emit('scrollToTop')},"onType":function($event){return _vm.$emit('onType')},"edit":function($event){return _vm.$emit('edit', $event)},"remove":function($event){return _vm.$emit('remove', $event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("header")]},proxy:true},{key:"user-avatar",fn:function(scopedProps){return [_vm._t("user-avatar",null,{"user":scopedProps.user,"message":scopedProps.message})]}},{key:"text-message-body",fn:function(scopedProps){return [_vm._t("text-message-body",null,{"message":scopedProps.message,"messageText":scopedProps.messageText,"messageColors":scopedProps.messageColors,"me":scopedProps.me})]}},{key:"system-message-body",fn:function(scopedProps){return [_vm._t("system-message-body",null,{"message":scopedProps.message})]}},{key:"text-message-toolbox",fn:function(scopedProps){return [_vm._t("text-message-toolbox",null,{"message":scopedProps.message,"me":scopedProps.me})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }