<template>
  <div>
    <Launcher
      v-if="projectid && ((website !== '' && botName !== '') || website === '')"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :close="closeChat"
      :colors="colors"
      :is-open="isChatOpen"
      :message-list="messageList"
      :message-styling="messageStyling"
      :new-messages-count="newMessagesCount"
      :open="openChat"
      :participants="participants"
      :showHeader="true"
      :show-close-button="true"
      :show-launcher="true"
      :show-emoji="true"
      :show-file="false"
      :show-typing-indicator="showTypingIndicator"
      :show-edition="false"
      :show-deletion="false"
      :title="botName || ''"
      :title-image-url="titleImageUrl"
      :disable-user-list-toggle="true"
      :tooltip="tooltip"
      :placeholder="placeholder"
      :isDemo="isDemo"
      @onType="handleOnType"
      :fullScreenMode="fullScreenMode"
    >
      <template v-slot:text-message-body="scopedProps">
        <span
          class="sc-message--text-content"
          v-html="scopedProps.messageText"
        ></span>
        <p
          v-if="scopedProps.message.data.meta"
          class="sc-message--meta"
          :style="{ color: scopedProps.messageColors.color }"
        >
          {{ scopedProps.message.data.meta }}
        </p>
      </template>
      <template v-slot:system-message-body="{ message }">
        [System]: {{ message.text }}
      </template>
    </Launcher>
  </div>
</template>

<script>
import store from "./store";
import loader from "./load";
import Launcher from "./Launcher.vue";
import { mapGetters } from "vuex";
import { FirebaseFunctions } from "@/config/firebase.js";
// import analytics from "./analytics";

if (loader) {
  const love = String.fromCodePoint(0x2764);
  console.log(
    "%cLife is better with fresh baked cookies " + love + "lovi",
    "color: Crimson;"
  );
}
export default {
  store,
  name: "LoviWidget",
  components: {
    // TestArea,
    Launcher,
  },
  props: {
    projectid: {
      type: String,
      required: true,
    },
    website: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      participants: [],
      newMessagesCount: 0,
      isChatOpen: false,
      showTypingIndicator: "",
      defaultColors: {
        header: {
          bg: "#D32F2F",
          text: "#fff",
        },
        launcher: {
          bg: "#D32F2F",
        },
        messageList: {
          bg: "#f7f2f2",
        },
        sentMessage: {
          bg: "#F44336",
          text: "#fff",
        },
        receivedMessage: {
          bg: "#fff",
          text: "#222222",
        },
        userInput: {
          bg: "#fff",
          text: "#212121",
        },
        userList: {
          bg: "#fff",
          text: "#212121",
        },
      },
      alwaysScrollToBottom: true,
      messageStyling: true,
      userIsTyping: false,
      tooltip: { show: false, title: "", subtitle: "" },
      widgetColor: "",
      botName: "",
      botImage: "",
      isDemo: false,
      fullScreenMode: false,
      ipData: {},
      // projectId: "XrPyooJFZIVgsGjcMylz",
      // user: "7IukL3mic2ftYO48tqJr1",
    };
  },
  computed: {
    ...mapGetters({ messageList: "chatModule/messages" }),
    titleImageUrl() {
      return (
        this.botImage ||
        this.$store.state.widgetModule.widget.image_url ||
        require("!!url-loader!@/assets/logo.png")
      );
    },
    lang() {
      return this.$store.state.currentLang;
      // if (this.$store.state.currentLang) {
      //   return this.$store.state.currentLang;
      // }
      // return this.getLang();
    },
    placeholder() {
      let placeholders = {
        es: "Escribe un mensaje...",
        en: "Type a message...",
        fr: "Tapez un message...",
        de: "Schreiben Sie eine Nachricht...",
        it: "Scrivi un messaggio...",
        ca: "Escriu un missatge...",
        va: "Escriu un missatge...",
        pt: "Digite uma mensagem...",
        ru: "Напишите сообщение...",
        eu: "Idatzi mezu bat...",
        gl: "Escribe unha mensaxe...",
        zh: "输入一条消息...",
        ja: "メッセージを入力...",
        ko: "메시지를 입력하십시오...",
      };
      return placeholders[this.$store.state.currentLang];
    },
    colors() {
      let colors = this.defaultColors;
      if (this.$store.state.widgetModule.widget) {
        colors.header.bg = this.$store.state.widgetModule.widget.color;
        colors.launcher.bg = this.$store.state.widgetModule.widget.color;
        colors.sentMessage.bg = this.$store.state.widgetModule.widget.color;
        colors.sentMessage.text = this.is_light(
          this.$store.state.widgetModule.widget.color
        )
          ? "#222222"
          : "#FFF";
        colors.header.text = this.is_light(
          this.$store.state.widgetModule.widget.color
        )
          ? "#222222"
          : "#FFF";
      }
      if (this.widgetColor !== "") {
        colors.header.bg = this.widgetColor;
        colors.launcher.bg = this.widgetColor;
        colors.sentMessage.bg = this.widgetColor;
        colors.sentMessage.text = this.is_light(this.widgetColor)
          ? "#222222"
          : "#FFF";
        colors.header.text = this.is_light(this.widgetColor)
          ? "#222222"
          : "#FFF";
      }
      return colors;
    },
  },
  created() {},
  async mounted() {
    await fetch("https://get.geojs.io/v1/ip/geo.json")
      .then((response) => response.json())
      .then((data) => {
        // Verificar si la respuesta contiene datos
        if (data && Object.keys(data).length > 0) {
          //           {
          //     "ip": "37.13.189.117",
          //     "organization_name": "Telefonica De Espana S.a.u.",
          //     "city": "Lalin",
          //     "asn": 3352,
          //     "organization": "AS3352 Telefonica De Espana S.a.u.",
          //     "country": "Spain",
          //     "area_code": "0",
          //     "timezone": "Europe/Madrid",
          //     "country_code": "ES",
          //     "country_code3": "ESP",
          //     "continent_code": "EU",
          //     "accuracy": 50,
          //     "region": "Galicia",
          //     "latitude": "42.6617",
          //     "longitude": "-8.1259"
          // }
          this.ipData = data;
        }
      });

    try {
      if (Promise.prototype.finally == null) {
        Promise.prototype.finally = function(handler) {
          return this.then(handler, handler);
        };
      }
      // Save this.projectid as a global variable

      this.$store.dispatch("widgetModule/closeDBChannel");

      this.$store.dispatch("userModule/setPathVars", {
        projectId: this.projectid,
      });
      await this.$store.dispatch("widgetModule/setPathVars", {
        projectId: this.projectid,
      });
      await this.$store.dispatch("widgetModule/openDBChannel");
      if (!this.$store.state.LoviUserId) {
        this.$store.state.LoviUserId = {};
      }
      // save website to a cookie

      if (this.website !== "") {
        if (!this.$store.state.LoviUserId[this.website]) {
          let magic = await FirebaseFunctions.httpsCallable(
            "gpt-getColorAndFavicon"
          )({
            domain: this.website,
          })
            .then((magic) => {
              console.log("magic", magic);
              return magic;
            })
            .catch((err) => {
              console.log(err);
            });
          this.isDemo = true;
          this.widgetColor = magic.data.color;
          this.botName = magic.data.botName;
          this.botImage = magic.data.url;
          this.$store.state.LoviUserId[this.website] = {
            widgetColor: this.widgetColor,
            botName: this.botName,
            botImage: this.botImage,
          };
        } else {
          this.widgetColor = this.$store.state.LoviUserId[
            this.website
          ].widgetColor;
          this.botName = this.$store.state.LoviUserId[this.website].botName;
          this.botImage = this.$store.state.LoviUserId[this.website].botImage;
        }
      }
      if (!this.$store.state.LoviUserId[this.projectid]) {
        let lang = this.getLang();
        this.$store.state.currentLang = lang;

        this.$store
          .dispatch("userModule/set", {
            firstVisit: document.location.href,
            created_via: "web",
            lang: lang,
            website: this.website,
            email: this.email || "",
            botName: this.botName,
            botIcon: this.botImage,
            widgetColor: this.widgetColor,
            ip: this.ipData.ip || "",
            country: this.ipData.country || "",
            region: this.ipData.region || "",
            city: this.ipData.city || "",
            latitude: this.ipData.latitude || "",
            longitude: this.ipData.longitude || "",
            timezone: this.ipData.timezone || "",
            continent_code: this.ipData.continent_code || "",
            country_code: this.ipData.country_code || "",
            country_code3: this.ipData.country_code3 || "",
            area_code: this.ipData.area_code || "",
            accuracy: this.ipData.accuracy || "",
          })
          .then((id) => {
            this.$store.state.LoviUserId[this.projectid] = id;
            this.syncMessages();
          });
      } else {
        this.syncMessages();
      }
      let lang = this.getLang();
      this.$store.state.currentLang = lang;
      console.log("Lovi API Active");
      window.loviWidget = document.getElementsByTagName(
        "lovi-widget"
      )[0].vueComponent;
      let meta = document.querySelector("meta[name=viewport]"); // selecciona la etiqueta meta por su nombre
      // si no existe la etiqueta meta, la crea
      if (!meta) {
        meta = document.createElement("meta");
        document.getElementsByTagName("head")[0].appendChild(meta);
      }
      meta.setAttribute("name", "viewport"); // establece el nombre del atributo
      meta.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, interactive-widget=resizes-content"
      ); // establece el valor del atributo
      // añade <meta name="apple-mobile-web-app-capable" content="yes"> para ios
      let meta2 = document.querySelector(
        "meta[name=apple-mobile-web-app-capable]"
      );
      if (!meta2) {
        meta2 = document.createElement("meta");
        document.getElementsByTagName("head")[0].appendChild(meta2);
      }
      meta2.setAttribute("name", "apple-mobile-web-app-capable");
      meta2.setAttribute("content", "yes");
    } catch (e) {
      // Do nothing. Because the channel is already open.
    }
    if (this.$store.state.widgetModule.widget.show_tooltip) {
      this.setTooltip("¿Necesitas ayuda?");
    }
  },
  methods: {
    is_light(color) {
      if (color) {
        const hex = color.replace("#", "");
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
        return brightness > 155;
      } else {
        return false;
      }
    },
    getLang() {
      if (document.cookie.indexOf("widgetLang") > -1) {
        return document.cookie.split("widgetLang=")[1].split(";")[0];
      }

      if (
        this.$store.state.widgetModule.widget.languages &&
        navigator.languages &&
        navigator.languages.length
      ) {
        for (let i = 0; i < navigator.languages.length; i++) {
          if (
            this.$store.state.widgetModule.widget.languages.indexOf(
              navigator.languages[i]
            ) > -1
          )
            return navigator.languages[i];
        }
        return this.$store.state.widgetModule.widget.languages[0];
      }

      return "es";
    },
    syncMessages() {
      // this.$store.dispatch(
      //   "chatModule/setUserId",
      //   this.$store.state.LoviUserId
      // );

      try {
        this.$store.dispatch("chatModule/closeDBChannel", {
          clearModule: true,
        });
        // var date = new Date();
        // let yesterday = date.setDate(date.getDate() - 1);
        window.loviproject = this.projectid;
        window.loviuser = this.$store.state.LoviUserId[this.projectid];
        this.$store.dispatch("chatModule/setPathVars", {
          projectId: this.projectid,
          // userId: this.$store.state.LoviUserId,
          user: this.$store.state.LoviUserId[this.projectid],
        });
        this.$store.dispatch(
          "chatModule/openDBChannel"
          // {
          //   clauses: { where: [["created_at", ">", yesterday]] },
          // }
        );
      } catch (e) {
        // Do nothing. Because the channel is already open.
      }
    },
    changeLang(lang) {
      this.$store.commit("setCurrentLang", lang);
      document.cookie = `widgetLang=${lang}`;
      // this.$store.dispatch("chatModule/set", {
      //   type: "event",
      //   data: { event: "welcome", changeLang: lang },
      // });
    },
    openChat() {
      // analytics.logEvent("openWidget");
      this.isChatOpen = true;
      this.newMessagesCount = 0;

      if (!this.messageList.length) {
        // this.$store.dispatch("chatModule/set", {
        //   type: "event",
        //   data: { event: "welcome" },
        // });
      }
    },
    setTooltip(title, subtitle) {
      this.tooltip.title = title;
      this.tooltip.subtitle = subtitle;
      this.tooltip.show = true;
    },
    closeChat() {
      this.isChatOpen = false;
    },
    handleOnType() {
      this.$root.$emit("onType");
      this.userIsTyping = true;
    },
    fullScreen() {
      this.fullScreenMode = true;
    },
  },
};
</script>

<style scoped>
body {
  padding: 0px;
  margin: 0px;
}

* {
  font-family: Helvetica Neue, Helvetica, sans-serif;
}

.demo-description {
  max-width: 500px;
}

.demo-description img {
  max-width: 500px;
}

.demo-test-area {
  width: 300px;
  box-sizing: border-box;
}

.demo-test-area--text {
  box-sizing: border-box;
  width: 100%;
  margin: 0px;
  padding: 0px;
  resize: none;
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
  background: #fafbfc;
  color: #8da2b5;
  border: 1px solid #dde5ed;
  font-size: 16px;
  padding: 16px 15px 14px;
  margin: 0;
  border-radius: 6px;
  outline: none;
  height: 150px;
  margin-bottom: 10px;
}

.demo-monster-img {
  width: 400px;
  display: block;
  margin: 60px auto;
}

.text-center {
  text-align: center;
}

.colors a {
  color: #fff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 10px;
}

.toggle a {
  text-decoration: none;
}

.messageStyling {
  font-size: small;
}
</style>
