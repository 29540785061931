<template>
  <div>
    <div
      @click.prevent="isOpen ? close() : openAndFocus()"
      v-if="showLauncher"
      class="sc-launcher"
      :class="{ opened: isOpen, right: !positionLeft, left: positionLeft }"
      :style="{
        backgroundColor: isOpen ? colors.launcher.bg : 'transparent',
        color: colors.launcher.bg,
        left:  positionLeft ? positionMarginX + 'px' : 'initial',
        right: !positionLeft ? positionMarginX + 'px' : 'initial',
        bottom: positionMarginY + 'px',
      }"
    >
      <tooltipMessage
        v-if="showLauncher && !isOpen"
        :tooltip="tooltip"
        @close="tooltip.show = closeTooltip"
        :left="positionLeft"
        :color="colors.launcher.bg"
      />
      <div
        v-if="newMessagesCount > 0 && !isOpen"
        class="sc-new-messsages-count"
      >
        {{ newMessagesCount }}
      </div>
      <img
        v-if="isOpen"
        :class="{
          'sc-closed-icon': true
        }"
        :style="{
          filter: is_light(colors.launcher.bg) ? '' : 'invert(100%)',
          bottom: positionMarginY + 'px'
        }"
        :src="icons.close.img"
        :alt="icons.close.name"
      />
      <img
        v-else
        :class="{
          'sc-open-icon': true,
        }"
        :style="{
          bottom: positionMarginY + 'px'
        }"
        :src="titleImageUrl"
        :alt="icons.open.name"
      />
    </div>
    <ChatWindow
      :whiteLabel="whiteLabel"
      :left="positionLeft"
      :marginx="positionMarginX || 20"
      :marginy="positionMarginY || 20"
      :backgroundImageWidget="backgroundImageWidget"
      :message-list="messageList"
      :on-user-input-submit="onMessageWasSent"
      :participants="participants"
      :title="chatWindowTitle"
      :is-open="isOpen"
      :show-emoji="showEmoji"
      :show-file="showFile"
      :show-header="showHeader"
      :placeholder="placeholder"
      :show-typing-indicator="showTypingIndicator"
      :colors="colors"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :message-styling="messageStyling"
      :isDemo="isDemo"
      :fullscreen="fullScreenMode"
      @close="close"
      @scrollToTop="$emit('scrollToTop')"
      @onType="$emit('onType')"
      @edit="$emit('edit', $event)"
      @remove="$emit('remove', $event)"
    >
      <template v-slot:header>
        <slot name="header"> </slot>
      </template>
      <template v-slot:user-avatar="scopedProps">
        <slot
          name="user-avatar"
          :user="scopedProps.user"
          :message="scopedProps.message"
        >
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot
          name="text-message-body"
          :message="scopedProps.message"
          :messageText="scopedProps.messageText"
          :messageColors="scopedProps.messageColors"
          :me="scopedProps.me"
        >
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message"> </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot
          name="text-message-toolbox"
          :message="scopedProps.message"
          :me="scopedProps.me"
        >
        </slot>
      </template>
    </ChatWindow>
  </div>
</template>

<script>
import store from "./store/";
import ChatWindow from "./ChatWindow.vue";
import tooltipMessage from "./messages/tooltipMessage.vue";
import CloseIconSvg from "!!url-loader!@/assets/close.svg";
// import OpenIcon from "./assets/logo-no-bg.svg";

export default {
  name: "Launcher",
  components: {
    ChatWindow,
    tooltipMessage,
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Object,
      default: function() {
        return {
          open: {
            // img: OpenIcon,
            name: "default",
          },
          close: {
            img: CloseIconSvg,
            name: "default",
          },
        };
      },
    },
    showEmoji: {
      type: Boolean,
      default: false,
    },
    showEdition: {
      type: Boolean,
      default: false,
    },
    showDeletion: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    open: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    showFile: {
      type: Boolean,
      default: false,
    },

    showCloseButton: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    participants: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: () => "",
    },
    titleImageUrl: {
      type: String,
      default: () => "",
    },
    // onMessageWasSent: {
    //   type: Function,
    //   required: true,
    // },
    // messageList: {
    //   type: Array,
    //   default: () => [],
    // },
    newMessagesCount: {
      type: Number,
      default: () => 0,
    },
    // placeholder: {
    //   type: String,
    //   default: "Escribe tu mensaje...",
    // },
    showTypingIndicator: {
      type: String,
      default: () => "",
    },
    fullScreenMode: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Object,
      validator: (c) =>
        "header" in c &&
        "bg" in c.header &&
        "text" in c.header &&
        "launcher" in c &&
        "bg" in c.launcher &&
        "messageList" in c &&
        "bg" in c.messageList &&
        "sentMessage" in c &&
        "bg" in c.sentMessage &&
        "text" in c.sentMessage &&
        "receivedMessage" in c &&
        "bg" in c.receivedMessage &&
        "text" in c.receivedMessage &&
        "userInput" in c &&
        "bg" in c.userInput &&
        "text" in c.userInput,
      default: function() {
        return {
          header: {
            bg: "#4e8cff",
            text: "#ffffff",
          },
          launcher: {
            bg: "#4e8cff",
          },
          messageList: {
            bg: "#ffffff",
          },
          sentMessage: {
            bg: "#4e8cff",
            text: "#ffffff",
          },
          receivedMessage: {
            bg: "#f4f7f9",
            text: "#ffffff",
          },
          userInput: {
            bg: "#f4f7f9",
            text: "#565867",
          },
        };
      },
    },
    alwaysScrollToBottom: {
      type: Boolean,
      default: () => false,
    },
    messageStyling: {
      type: Boolean,
      default: () => false,
    },
    disableUserListToggle: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
    },
  },
  computed: {
    getCurrentLang() {
      return this.$store.state.currentLang;
    },
    placeholder() {
      let placeholders = {
        es: "Escribe un mensaje...",
        en: "Type a message...",
        fr: "Tapez un message...",
        de: "Schreiben Sie eine Nachricht...",
        it: "Scrivi un messaggio...",
        ca: "Escriu un missatge...",
        pt: "Digite uma mensagem...",
        ru: "Напишите сообщение...",
        eu: "Idatzi mezu bat...",
        gl: "Escribe unha mensaxe...",
        va: "Escriu un missatge...",
        zh: "输入一条消息...",
        ja: "メッセージを入力...",
        ko: "메시지를 입력하십시오...",
      };
      return placeholders[this.getCurrentLang] || placeholders.es;
    },

    messageList() {
      return Object.values(this.$store.state.chatModule.messageList).sort(
        function(a, b) {
          if (!a.created_at || !b.created_at) {
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            if (!a.created_at) {
              a.created_at = tomorrow;
            } else {
              b.created_at = tomorrow;
            }
          }
          return new Date(a.created_at) - new Date(b.created_at);
        }
      );
    },
    whiteLabel() {
      return this.$store.state.widgetModule.widget.whiteLabel === true;
    },
    positionLeft() {
      return this.$store.state.widgetModule.widget.position === "left";
    },
    positionMarginX(){
      return this.$store.state.widgetModule.widget.marginx;
    },
    positionMarginY(){
      return this.$store.state.widgetModule.widget.marginy;
    },
    backgroundImageWidget(){
      return this.$store.state.widgetModule.widget.background_url;
    },
    showLauncher() {
      return this.$store.state.widgetModule.widget.name;
    },
    chatWindowTitle() {
      if (this.title !== "") return this.title;

      if (this.participants.length === 0)
        return this.$store.state.widgetModule.widget.name || "";
      if (this.participants.length > 1)
        return "You, " + this.participants[0].name + " & others";

      return "You & " + this.participants[0].name;
    },
  },
  watch: {
    $props: {
      deep: true,
      immediate: true,
      handler(props) {
        for (const prop in props) {
          store.commit("setState", { key: prop, value: props[prop] });
        }
      },
    },
  },
  methods: {
    is_light(color) {
      if (color) {
        const hex = color.replace("#", "");
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
        return brightness > 155;
      } else {
        return false;
      }
    },
    onMessageWasSent(message) {
      this.$store.dispatch("chatModule/set", message);
    },
    openAndFocus() {
      this.open();
      this.$root.$emit("focusUserInput");
    },
  },
};
</script>

<style scoped lang="scss">
.sc-launcher {
  z-index: 2147483647;
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 25px;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  animation: pulse-shadow--collection--parple 2s infinite;
}
@keyframes pulse-shadow--collection--parple {
  0% {
    -webkit-box-shadow: 0 0 currentColor;
    box-shadow: 0 0 currentColor;
  }
  80% {
    -webkit-box-shadow: 0 0 7px 15px rgba(238, 105, 128, 0);
    box-shadow: 0 0 7px 15px rgba(238, 105, 128, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(238, 105, 128, 0);
    box-shadow: 0 0 0 0 rgba(238, 105, 128, 0);
  }
}

.sc-launcher:before {
  content: "";
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
}

.sc-launcher .sc-open-icon,
.sc-launcher .sc-closed-icon {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 25px;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
}
/* if mobile remove left and right */
.right {
  right: 25px;
}
.left {
  left: 25px;
}
@media (max-width: 768px) {
  .right {
    right: 25px;
  }
  .left {
    left: 25px;
  }
}
.rightbubble {
  right: 85px;
}
.leftbubble {
  left: 85px;
}

.sc-launcher .sc-closed-icon {
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.sc-launcher .sc-open-icon {
  object-fit: contain;
  border-radius: 50%;

  box-sizing: border-box;
  opacity: 1;
}

.sc-launcher.opened .sc-open-icon {
  transform: rotate(-90deg);
  opacity: 1;
}

.sc-launcher.opened .sc-closed-icon {
  transform: rotate(-90deg);
  opacity: 1;
}

.sc-launcher.opened:before {
  box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.sc-launcher:hover {
  box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
}

.sc-new-messsages-count {
  position: absolute;
  top: -3px;
  left: 41px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: #ff4646;
  color: white;
  text-align: center;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
}
</style>
