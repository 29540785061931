var loviProjectId = document.currentScript.getAttribute("lovi-id");
var website = document.currentScript.getAttribute("website") || "";
var lang = document.currentScript.getAttribute("lang");
var email = document.currentScript.getAttribute("email");
var lovi;

if (lang) {
  document.cookie = "widgetLang=".concat(lang);
}
function safeDecorator(fn) {
  // eslint-disable-next-line func-names
  return function (...args) {
    try {
      return fn.apply(this, args);
    } catch (error) {
      if (
        error instanceof DOMException &&
        error.message.includes("has already been used with this registry")
      ) {
        return false;
      }
      throw error;
    }
  };
}

customElements.define = safeDecorator(customElements.define);

loadLoviWidget(loviProjectId);

function loadLoviWidget(loviProjectId) {
  if (!document.getElementsByTagName("lovi-widget").length) {
    try {
      if (loviProjectId) {
        if (!document.getElementsByTagName("lovi-widget")[0]) {
          lovi = document.createElement("lovi-widget");
          lovi.setAttribute("projectid", loviProjectId);
          if (website) {
            lovi.setAttribute("website", website);
          }
          if (email) {
            lovi.setAttribute("email", email);
          }
          if (document.getElementById("lovi-container")) {
            document.getElementById("lovi-container").append(lovi);
          } else {
            document.body.append(lovi);
          }
        } else {
          document.getElementsByTagName("lovi-widget")[0].remove();

          lovi = document.createElement("lovi-widget");

          lovi.setAttribute("projectid", loviProjectId);
          if (website) {
            lovi.setAttribute("website", website);
          }
          if (email) {
            lovi.setAttribute("email", email);
          }

          document.body.append(lovi);
        }
      } else {
        if (
          !document
            .getElementsByTagName("lovi-widget")[0]
            .getAttribute("projectid")
        )
          console.error(
            "Can´t mount Lovi Web Widget. Please check your Lovi integration code."
          );
      }
    } catch {
      // console.error("Error loading Lovi");
    }
  }
}

document.onload = loadLoviWidget(loviProjectId);
