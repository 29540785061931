import firebase from "firebase/app";
const Firebase = firebase;
// import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
// import "firebase/storage";
import "firebase/functions";
// import * as Sentry from "@sentry/vue";

const firebaseConfig = {
  apiKey: "AIzaSyDkUEMORoG4qiP4NMDkbJShU_cGdcaENYw",
  authDomain: "botgpt-cd2b5.firebaseapp.com",
  databaseURL: "https://botgpt-cd2b5-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "botgpt-cd2b5",
  storageBucket: "botgpt-cd2b5.appspot.com",
  messagingSenderId: "810266570615",
  appId: "1:810266570615:web:936acff6235f12f1f27b72",
  measurementId: "G-SSYZR2VHYX"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBrzzJI1_CGuDHSFO9pmKBajl0xyQmRFuY",
//   // authDomain: "botsloversplatform.firebaseapp.com",
//   projectId: "botsloversplatform",
//   storageBucket: "botsloversplatform.appspot.com",
//   messagingSenderId: "928213458837",
//   appId: "1:928213458837:web:cb600cd9b2dfcdd28aa09c",
//   measurementId: "G-DB02Q226B6"
// };

!Firebase.apps.length ? Firebase.initializeApp(firebaseConfig) : "";
export var FirebaseFunctions = firebase.app().functions("europe-west3");
export var FirebaseDatabase = Firebase.database();


if (process.env.NODE_ENV === "development") {
  Firebase.firestore().useEmulator("localhost", 5002);
  FirebaseFunctions.useEmulator("localhost", 5001);
  FirebaseDatabase.useEmulator("localhost", 9000);

  // Firebase.auth().useEmulator("http://localhost:9099");
}

// Realtime Database

// export const GoogleAuthProvider = new Firebase.auth.GoogleAuthProvider();
// export const FirebaseAuth = Firebase.auth();
// export const FirebaseStorage = Firebase.storage();
export const Firestore = Firebase.firestore();

export default Firebase;
