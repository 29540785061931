const sharedDataModule = {
  firestorePath: "/projects/{projectId}/users/{user}/sharedData",
  firestoreRefType: "collection",
  moduleName: "sharedDataModule",
  statePropName: "sharedData",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%",
    },
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
};

export default sharedDataModule;
