const userModule = {
  firestorePath: "/projects/{projectId}/users/",
  firestoreRefType: "collection",
  moduleName: "userModule",
  statePropName: "user",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%",
    },
  },
  sync: {
    guard: ["created_by"],
    defaultValues: {
      created_via: "web",
      default_lang: window.navigator.language,
      default_langs: window.navigator.languages,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
};

export default userModule;
