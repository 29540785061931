var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.message.type != 'score' || _vm.isLastMessage)?_c('div',{staticClass:"sc-message",attrs:{"id":_vm.message.id}},[_c('div',{staticClass:"sc-message--content",class:{
      sent: _vm.message.is_user,
      received: !_vm.message.is_user && _vm.message.type !== 'system',
      system: _vm.message.type === 'system',
    }},[_vm._t("user-avatar",[(_vm.message.type !== 'system' && _vm.created_byName && !_vm.message.is_user)?_c('div',{staticClass:"sc-message--avatar",style:({
          backgroundImage: ("url(" + _vm.chatImageUrl + ")"),
        }),attrs:{"tooltip":"created_byName","title":_vm.created_byName}}):_vm._e()],{"message":_vm.message,"user":_vm.user}),(_vm.message.type === 'text')?_c('TextMessage',{attrs:{"message":_vm.message,"message-colors":_vm.messageColors,"message-styling":_vm.messageStyling,"primaryColor":_vm.colors.header.bg,"buttons":_vm.message.data.buttons || []},on:{"remove":function($event){return _vm.$emit('remove')}},scopedSlots:_vm._u([{key:"default",fn:function(scopedProps){return [_vm._t("text-message-body",null,{"message":scopedProps.message,"messageText":scopedProps.messageText,"messageColors":scopedProps.messageColors,"me":scopedProps.me})]}},{key:"text-message-toolbox",fn:function(scopedProps){return [_vm._t("text-message-toolbox",null,{"message":scopedProps.message,"me":scopedProps.me})]}}],null,true)}):(_vm.message.type === 'emoji')?_c('EmojiMessage',{attrs:{"data":_vm.message.data}}):(_vm.message.type === 'file')?_c('FileMessage',{attrs:{"data":_vm.message.data,"message-colors":_vm.messageColors}}):(_vm.message.type === 'carrousel')?_c('CarrouselMessage',{attrs:{"data":_vm.message.data,"message-colors":_vm.messageColors,"backgroundColor":_vm.colors.header.bg},on:{"sendQuestion":_vm.sendQuestion}}):(_vm.message.type === 'score' && _vm.isLastMessage)?_c('ScoreMessage',{attrs:{"data":_vm.message.data,"backgroundColor":_vm.colors.header.bg},on:{"sendQuestion":_vm.sendQuestion}}):(_vm.message.type === 'live')?_c('LiveMessage',{attrs:{"message-colors":_vm.messageColors,"message-styling":_vm.messageStyling,"primaryColor":_vm.colors.header.bg,"buttons":[]},on:{"updated":_vm.updated}}):(_vm.message.type === 'typing')?_c('TypingMessage',{attrs:{"message-colors":{
        color: this.colors.sentMessage.text,
        backgroundColor: this.colors.sentMessage.bg,
      }}}):(_vm.message.type === 'welcome')?_c('WelcomeMessage',{attrs:{"message-colors":_vm.messageColors,"botName":_vm.botName}}):(_vm.message.type === 'system')?_c('SystemMessage',{attrs:{"data":_vm.message.data,"message-colors":_vm.messageColors}},[_vm._t("system-message-body",null,{"message":_vm.message.data})],2):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }