<template>
  <div style="display: flex">
    <div
      class="sc-message--text"
      v-if="data.text"
      style="max-width: 230px; font-size: 13px"
    >
      <div class="sc-message--toolbox">
        <span class="sc-message--text-content">{{ data.text }}</span>
      </div>
    </div>
    <div class="content_buttons" v-if="contentButtons">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        @click="onMessageWasSent('thumbs_up')"
        :style="{ color: backgroundColor }"
        style="border: 2px solid; border-radius: 50%; padding: 4px"
        viewBox="0 0 24 24"
        fill="none"
        :stroke="backgroundColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-thumbs-up button_calificacion"
      >
        <path
          d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"
        ></path>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        @click="onMessageWasSent('thumbs_down')"
        :style="{ color: backgroundColor }"
        style="border: 2px solid; border-radius: 50%; padding: 4px"
        viewBox="0 0 24 24"
        fill="none"
        :stroke="backgroundColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-thumbs-down button_calificacion"
      >
        <path
          d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"
        ></path>
      </svg>
    </div>
  </div>
</template>


<script>
export default {
  name:"ScoreMessage",
  methods: {
    onMessageWasSent(message) {
      this.contentButtons = false;
      this.$store.dispatch("chatModule/set", {
        type: "event",
        data: { event: message, about: this.data.about },
      });
    },
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      contentButtons: true,
      moving: false,
      name: "default",
    };
  },
};
</script>

<style scoped lang="scss">
.sc-message--text {
  // padding: 5px 20px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  // background-color: rgb(234, 234, 234);
  // margin-bottom: 10px;
  // margin-right: 40px;
  place-self: center;
}
.content_buttons {
  text-align: center;
  align-self: center;
  display: flex;
  margin: 10px;
}
.button_calificacion {
  border: none;
  cursor: pointer;
  background: inherit;
  margin: 4px;
}
.button_calificacion_bad {
  border-radius: 50px 50px 50px 50px;
  border: 0px solid #ef4252;
  cursor: pointer;
}
.button_calificacion_bad svg {
  position: relative;

  top: 3px;
}
.sc-message--content {
  width: 100%;
  justify-content: center;
}
</style>
