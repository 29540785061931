<template>
  <div>
    <div class="sc-typing-indicator">
      <span></span>
      <span></span>
      <span></span>
      <br />
      <!-- <div
      v-if="message.show"
      class="sc-typing-indicator--message"
      style="color: grey"
      >
      {{ message.text }}
    </div> -->
    </div>
    <!-- on message change, make transition -->
    <div class="DNA_cont" :style="`--color:${color}`">
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
      <div class="nucleobase"></div>
    </div>
    <!-- <div
        v-if="message.show"
        class="sc-typing-indicator--message"
        style="color: grey"
      >
        {{ message.text }}
      </div> -->
  </div>
</template>

<script>
export default {
  props: {
    messageColors: {
      type: Object,
      required: true,
    },
  },
  // show messages if the time is more than 3 seconds

  data() {
    return {
      messageList: [
        "",
        "Searching for your question",
        "Looking for the answer",
        "Finding the answer",
        "Coming up with the answer",
        "It's a tough one",
        "Let me think about it",
        "I'm not so sure",
      ],
      message: {
        show: false,
        text: "",
      },
    };
  },
  mounted() {
    this.message.text = this.messageList[0];
    this.message.show = true;
    this.interval = setInterval(() => {
      this.message.text = this.messageList[
        // rotate through the messages
        (this.messageList.indexOf(this.message.text) + 1) %
          this.messageList.length
      ];
      this.message.show = false;
      this.message.show = true;
    }, 6000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    color() {
      // var background = "#f7f2f2";
      if (
        this.esColorCercano(this.messageColors.backgroundColor, "#f7f2f2", 50)
      ) {
        return "#000000";
      }
      return this.messageColors.backgroundColor;
    },
  },
  methods: {
    esColorCercano(color, fondo, umbral) {
      color = parseInt(color.replace(/^#/, ""), 16);
      fondo = parseInt(fondo.replace(/^#/, ""), 16);
      // Obtener los componentes de color del fondo
      var fondoRojo = (fondo >> 16) & 255;
      var fondoVerde = (fondo >> 8) & 255;
      var fondoAzul = fondo & 255;

      // Obtener los componentes de color del color a verificar
      var colorRojo = (color >> 16) & 255;
      var colorVerde = (color >> 8) & 255;
      var colorAzul = color & 255;

      // Calcular la diferencia entre los componentes de color
      var diferenciaRojo = Math.abs(fondoRojo - colorRojo);
      var diferenciaVerde = Math.abs(fondoVerde - colorVerde);
      var diferenciaAzul = Math.abs(fondoAzul - colorAzul);

      // Verificar si la diferencia está dentro del umbral
      console.log(diferenciaRojo, diferenciaVerde, diferenciaAzul);
      return (
        diferenciaRojo <= umbral &&
        diferenciaVerde <= umbral &&
        diferenciaAzul <= umbral
      );
    },
  },
  style: {
    color: "white",
  },
};
</script>

<style scoped lang="scss">
.nucleobase {
  &:before,
  &:after {
    background-color: var(--color);
  }
}

$b-height: 0.8em;
$el-size: 1vh;
$DNA_scale: 0.45; //divides '$el-size'
$t: 2.1s;
$td: -0.89;
$c_a: rgb(255, 132, 144) rgb(255, 115, 124) rgb(245, 247, 249); // top / bottom / BG
$c2_a: rgb(255, 218, 178) rgb(255, 50, 155); // Fade-anim colors

$ease-circ: cubic-bezier(0.42, 0, 0.58, 1);

.DNA_cont {
  display: inline-block;
  position: relative;
  transform: scale($DNA_scale);
}

.nucleobase {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  //  width: 1px; height: $el-size*2.75;
  //  background: transparent;
  //  box-shadow: $el-size*0.475 $el-size*-0.85 0 rgba(0,0,0,0.2);

  &:not(:last-child) {
    margin-right: $el-size * 1.62;
  }

  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: $el-size;
    height: $el-size;
    border-radius: 50%;

    position: absolute;
  }

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      // animation: animDotBar $t $ease-circ infinite;

      animation-delay: $i * ($td * $t);
      &:before {
        // transform: translateY(-200%);

        animation: animBefore $t $ease-circ infinite;

        animation-delay: $i * ($td * $t);
      }

      &:after {
        // transform: translateY(200%);

        animation: animAfter $t $ease-circ infinite;

        animation-delay: $i * ($td * $t);
      }
    }
  }
}

@keyframes animBefore {
  0% {
    top: $el-size * -2;
    z-index: 1;
  }

  25% {
    transform: scale(1.2);
    z-index: 1;
  }

  50% {
    // background-color: nth($c_a, 1) ;
    top: $el-size * 2;
    z-index: -1;
  }

  75% {
    transform: scale(0.8);
    z-index: -1;
  }

  100% {
    top: $el-size * -2;
    z-index: -1;
  }
}

@keyframes animAfter {
  0% {
    top: $el-size * 2;
    z-index: -1;
  }

  25% {
    transform: scale(0.8);
    z-index: -1;
  }

  50% {
    //  background-color: nth($c_a, 2) ;
    top: $el-size * -2;
    z-index: 1;
  }

  75% {
    transform: scale(1.2);
    z-index: 1;
  }

  100% {
    top: $el-size * 2;
    z-index: 1;
  }
}

@keyframes animDotBar {
  // NOT USED
  0% {
    height: $el-size * 2.75;
  }

  25% {
    height: 0;
  }

  50% {
    height: $el-size * 2.75;
  }

  75% {
    height: 0;
  }

  100% {
    height: $el-size * 2.75;
  }
}

@keyframes superscript {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0em);
  }
}
</style>
