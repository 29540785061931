<template>
  <div>
    <FormMessage
      v-if="form && form.fields"
      :form="form"
      :colors="colors"
      @submitForm="_submitForm"
      @exitForm="form = {}"
    />
    <PrivacyMessage
      v-if="privacy.active && !aceptedPrivacy"
      :privacy="privacy"
      :colors="colors"
      @aceptPrivacy="aceptPrivacy"
      @exitForm="form = {}"
    />

    <Suggestions
      :suggestions="suggestions"
      :colors="colors"
      @sendSuggestion="_submitSuggestion"
    />
    <div
      v-if="file"
      class="file-container"
      :style="{
        backgroundColor: colors.userInput.text,
        color: colors.userInput.bg,
      }"
    >
      <span class="icon-file-message"
        ><img :src="icons.file.img" :alt="icons.file.name" height="15"
      /></span>
      {{ file.name }}
      <span class="delete-file-message" @click="cancelFile()"
        ><img
          :src="icons.closeSvg.img"
          :alt="icons.closeSvg.name"
          height="10"
          title="Remove the file"
      /></span>
    </div>
    <form
      v-if="(!form || !form.fields) && (!privacy.active || aceptedPrivacy)"
      class="sc-user-input"
      :class="{ active: inputActive }"
      :style="{ background: colors.userInput.bg }"
    >
      <div
        ref="userInput"
        role="button"
        tabIndex="0"
        contentEditable="true"
        :placeholder="writeamessage"
        class="sc-user-input--text"
        :style="{ color: colors.userInput.text }"
        @focus="setInputActive(true)"
        @blur="setInputActive(false)"
        @keydown="handleKey"
        @focusUserInput="focusUserInput()"
      ></div>
      <div class="sc-user-input--buttons">
        <div v-if="showEmoji && !isEditing" class="sc-user-input--button">
          <EmojiIcon
            :on-emoji-picked="_handleEmojiPicked"
            :color="colors.userInput.text"
          />
        </div>
        <div v-if="showFile && !isEditing" class="sc-user-input--button">
          <FileIcons
            :on-change="_handleFileSubmit"
            :color="colors.userInput.text"
          />
        </div>
        <div v-if="isEditing" class="sc-user-input--button">
          <UserInputButton
            :colors="colors.header"
            tooltip="Cancel"
            @click.native.prevent="_editFinish"
          >
            <IconCross />
          </UserInputButton>
        </div>
        <div class="sc-user-input--button">
          <UserInputButton
            v-if="isEditing"
            :colors="colors.header"
            tooltip="Edit"
            @click.native.prevent="_editText"
          >
            <IconOk />
          </UserInputButton>
          <UserInputButton
            v-else
            :colors="colors.header"
            tooltip="Send"
            @click.native.prevent="_submitText"
          >
            <IconSend />
          </UserInputButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EmojiIcon from "./icons/EmojiIcon.vue";
import FileIcons from "./icons/FileIcons.vue";
import UserInputButton from "./UserInputButton.vue";
import Suggestions from "./Suggestions.vue";
import FormMessage from "./messages/FormMessage.vue";
import PrivacyMessage from "./messages/PrivacyMessage.vue";
import FileIcon from "!!url-loader!./assets/file.svg";
import CloseIconSvg from "!!url-loader!@/assets/close.svg";
import store from "./store/";
import IconCross from "./components/icons/IconCross.vue";
import IconOk from "./components/icons/IconOk.vue";
import IconSend from "./components/icons/IconSend.vue";
// //"🖋️..."
export default {
  name: "UserInput",
  components: {
    EmojiIcon,
    FileIcons,
    UserInputButton,
    Suggestions,
    IconCross,
    IconOk,
    IconSend,
    FormMessage,
    PrivacyMessage,
  },
  props: {
    icons: {
      type: Object,
      default: function() {
        return {
          file: {
            img: FileIcon,
            name: "default",
          },
          closeSvg: {
            img: CloseIconSvg,
            name: "default",
          },
        };
      },
    },
    showEmoji: {
      type: Boolean,
      default: () => false,
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    showFile: {
      type: Boolean,
      default: () => false,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Write something...",
    },
    colors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      inputActive: false,
      aceptedPrivacy: localStorage.getItem("aceptedPrivacy"),
    };
  },
  computed: {
    privacy() {
      return this.$store.state.widgetModule.widget.privacy || { active: false };
    },
    writeamessage() {
      const lang = navigator.language || navigator.userLanguage;
      if (lang.includes("es")) {
        return "Escribe un mensaje...";
      } else if (lang.includes("pt")) {
        return "Escreva uma mensagem...";
      } else if (lang.includes("fr")) {
        return "Écrivez un message...";
      } else if (lang.includes("de")) {
        return "Schreiben Sie eine Nachricht...";
      } else if (lang.includes("it")) {
        return "Scrivi un messaggio...";
      } else if (lang.includes("ru")) {
        return "Напишите сообщение...";
      } else if (lang.includes("ja")) {
        return "メッセージを書く...";
      } else if (lang.includes("ko")) {
        return "메시지를 작성하십시오...";
      } else if (lang.includes("zh")) {
        return "写一条消息...";
      } else if (lang.includes("ar")) {
        return "اكتب رسالة...";
      } else {
        return "Message...";
      }
    },

    editMessageId() {
      return this.isEditing && store.state.editMessage.id;
    },
    isEditing() {
      return store.state.editMessage && store.state.editMessage.id;
    },
  },
  watch: {
    editMessageId() {
      //editMessageId(m)
      if (
        store.state.editMessage != null &&
        store.state.editMessage != undefined
      ) {
        this.$refs.userInput.focus();
        this.$refs.userInput.textContent = store.state.editMessage.data.text;
      } else {
        this.$refs.userInput.textContent = "";
      }
    },
  },
  mounted() {
    this.$root.$on("focusUserInput", () => {
      if (this.$refs.userInput) {
        this.focusUserInput();
      }
    });
  },
  methods: {
    cancelFile() {
      this.file = null;
    },
    setInputActive(onoff) {
      this.$emit("onInputActive", onoff);
      this.inputActive = onoff;
    },
    handleKey(event) {
      if (event.keyCode === 13 && !event.shiftKey) {
        if (!this.isEditing) {
          this._submitText(event);
        } else {
          this._editText(event);
        }
        this._editFinish();
        event.preventDefault();
      } else if (event.keyCode === 27) {
        this._editFinish();
        event.preventDefault();
      }

      this.$emit("onType");
    },
    focusUserInput() {
      this.$nextTick(() => {
        this.$refs.userInput.focus();
      });
    },
    aceptPrivacy() {
      // save acept privacy in localstorage

      localStorage.setItem("aceptedPrivacy", true);
      this.aceptedPrivacy = localStorage.getItem("aceptedPrivacy");
    },
    _submitForm(form) {
      this.onSubmit({
        type: "form",
        data: form,
      });
    },
    _submitSuggestion(suggestion) {
      this.onSubmit({
        type: "text",
        data: { text: suggestion },
      });
    },
    _checkSubmitSuccess(success) {
      if (Promise !== undefined) {
        Promise.resolve(success).then(
          function(wasSuccessful) {
            if (wasSuccessful === undefined || wasSuccessful) {
              this.file = null;
              this.$refs.userInput.innerHTML = "";
              //verificamos si es mobile
              if (
                navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)
              ) {
                this.$refs.userInput.blur();
              }
            }
          }.bind(this)
        );
      } else {
        this.file = null;
        this.$refs.userInput.innerHTML = "";
      }
    },
    _submitText(event) {
      const text = this.$refs.userInput.textContent;
      const file = this.file;
      if (file) {
        this._submitTextWhenFile(event, text, file);
      } else {
        if (text && text.length > 0) {
          this._checkSubmitSuccess(
            this.onSubmit({
              type: "text",
              data: { text },
            })
          );
        }
      }
    },
    _submitTextWhenFile(event, text, file) {
      if (text && text.length > 0) {
        this._checkSubmitSuccess(
          this.onSubmit({
            type: "file",
            data: { text, file },
          })
        );
      } else {
        this._checkSubmitSuccess(
          this.onSubmit({
            type: "file",
            data: { file },
          })
        );
      }
    },
    _editText() {
      // _editText(event) {

      const text = this.$refs.userInput.textContent;
      if (text && text.length) {
        this.$emit("edit", {
          type: "text",
          id: store.state.editMessage.id,
          data: { text },
        });
        this._editFinish();
      }
    },
    _handleEmojiPicked(emoji) {
      this._checkSubmitSuccess(
        this.onSubmit({
          type: "emoji",
          data: { emoji },
        })
      );
    },
    _handleFileSubmit(file) {
      this.file = file;
    },
    _editFinish() {
      store.commit("editMessage", null);
    },
  },
};
</script>

<style scoped>
.sc-user-input {
  min-height: 50px;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.sc-user-input--text {
  flex-grow: 1;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 14px;
  font-size: 16px;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  filter: contrast(15%);
  outline: none;
  cursor: text;
}

.sc-user-input--buttons {
  top: -20px;
  position: absolute;
  right: 20px;
}

.file-container {
  background-color: #f4f7f9;
  border-top-left-radius: 10px;
  padding: 5px 20px;
  color: #565867;
}

.delete-file-message {
  font-style: normal;
  float: right;
  cursor: pointer;
  color: #c8cad0;
}

.delete-file-message:hover {
  color: #5d5e6d;
}

.icon-file-message {
  margin-right: 5px;
}

@media only screen and (max-width: 480px) {
  .sc-user-input--buttons {
    top: -30px;
    right: 10px;
  }
  .sc-user-input--text {
    padding-right: 70px;
  }
}
</style>
