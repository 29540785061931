<template>
  <div
    :id="message.id"
    class="sc-message"
    v-if="message.type != 'score' || isLastMessage"
  >
    <div
      class="sc-message--content"
      :class="{
        sent: message.is_user,
        received: !message.is_user && message.type !== 'system',
        system: message.type === 'system',
      }"
    >
      <slot name="user-avatar" :message="message" :user="user">
        <div
          v-if="message.type !== 'system' && created_byName && !message.is_user"
          tooltip="created_byName"
          :title="created_byName"
          class="sc-message--avatar"
          :style="{
            backgroundImage: `url(${chatImageUrl})`,
          }"
        ></div>
      </slot>

      <TextMessage
        v-if="message.type === 'text'"
        :message="message"
        :message-colors="messageColors"
        :message-styling="messageStyling"
        :primaryColor="colors.header.bg"
        :buttons="message.data.buttons || []"
        @remove="$emit('remove')"
      >
        <template v-slot:default="scopedProps">
          <slot
            name="text-message-body"
            :message="scopedProps.message"
            :messageText="scopedProps.messageText"
            :messageColors="scopedProps.messageColors"
            :me="scopedProps.me"
          >
          </slot>
        </template>
        <template v-slot:text-message-toolbox="scopedProps">
          <slot
            name="text-message-toolbox"
            :message="scopedProps.message"
            :me="scopedProps.me"
          >
          </slot>
        </template>
      </TextMessage>
      <EmojiMessage v-else-if="message.type === 'emoji'" :data="message.data" />
      <FileMessage
        v-else-if="message.type === 'file'"
        :data="message.data"
        :message-colors="messageColors"
      />
      <CarrouselMessage
        v-else-if="message.type === 'carrousel'"
        :data="message.data"
        :message-colors="messageColors"
        :backgroundColor="colors.header.bg"
        @sendQuestion="sendQuestion"
      />
      <ScoreMessage
        v-else-if="message.type === 'score' && isLastMessage"
        :data="message.data"
        :backgroundColor="colors.header.bg"
        @sendQuestion="sendQuestion"
      />
      <LiveMessage
        v-else-if="message.type === 'live'"
        :message-colors="messageColors"
        :message-styling="messageStyling"
        :primaryColor="colors.header.bg"
        :buttons="[]"
        @updated="updated"
      />
      <TypingMessage
        v-else-if="message.type === 'typing'"
        :message-colors="{
          color: this.colors.sentMessage.text,
          backgroundColor: this.colors.sentMessage.bg,
        }"
      />
      <WelcomeMessage
        v-else-if="message.type === 'welcome'"
        :message-colors="messageColors"
        :botName="botName"
      />

      <SystemMessage
        v-else-if="message.type === 'system'"
        :data="message.data"
        :message-colors="messageColors"
      >
        <slot name="system-message-body" :message="message.data"> </slot>
      </SystemMessage>
    </div>
  </div>
</template>

<script>
import TextMessage from "./messages/TextMessage.vue";
import FileMessage from "./messages/FileMessage.vue";
import CarrouselMessage from "./messages/CarrouselMessage.vue";
import ScoreMessage from "./messages/ScoreMessage.vue";
import EmojiMessage from "./messages/EmojiMessage.vue";
import TypingMessage from "./messages/TypingMessage.vue";
import SystemMessage from "./messages/SystemMessage.vue";
import WelcomeMessage from "./messages/WelcomeMessage.vue";
import LiveMessage from "./messages/LiveMessage.vue";
import chatIcon from "!!url-loader!@/assets/logo.png";

export default {
  name: "Message",
  components: {
    TextMessage,
    FileMessage,
    CarrouselMessage,
    EmojiMessage,
    TypingMessage,
    SystemMessage,
    ScoreMessage,
    WelcomeMessage,
    LiveMessage,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    isLastMessage: {
      type: Boolean,
      required: false,
    },
    colors: {
      type: Object,
      required: true,
    },
    messageStyling: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    botName: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    sendQuestion(text) {
      this.$emit("sendQuestion", text);
    },
    updated() {
      this.$emit("updated");
    },
  },
  mounted() {
    if (!this.message.is_user && this.message.type == "typing") {
      this.message.show = false;
      setTimeout(() => {
        this.message.show = true;
      }, 200);
    }
  },
  computed: {
    created_byName() {
      return this.user && this.user.name;
    },
    chatImageUrl() {
      return (this.user && this.user.imageUrl) || chatIcon;
    },
    messageColors() {
      return this.message.is_user
        ? this.sentColorsStyle
        : this.receivedColorsStyle;
    },
    receivedColorsStyle() {
      return {
        color: this.colors.receivedMessage.text,
        backgroundColor: this.colors.receivedMessage.bg,
      };
    },
    sentColorsStyle() {
      return {
        color: this.colors.sentMessage.text,
        backgroundColor: this.colors.sentMessage.bg,
      };
    },
  },
};
</script>

<style scoped>
/* TODO: re-org and scope this style block */

.sc-message {
  margin-bottom: 20px;
  display: flex;
  animation: slide-up 0.6s ease;
  .sc-message--edited {
    opacity: 0.7;
    word-wrap: normal;
    font-size: xx-small;
    text-align: center;
  }
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.sc-message--content {
  width: 100%;
  display: flex;
}

.sc-message--content.sent {
  justify-content: flex-end;
}

.sc-message--content.system {
  justify-content: center;
}

.sc-message--content.sent .sc-message--avatar {
  display: none;
}

.sc-message--avatar {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 15px;
}

.sc-message--meta {
  font-size: xx-small;
  margin-bottom: 0px;
  color: white;
  text-align: center;
}

@media (max-width: 450px) {
  .sc-message {
    width: 95%;
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;
  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }
  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }
  &[x-placement^="top"] {
    margin-bottom: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="bottom"] {
    margin-top: 5px;
    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="right"] {
    margin-left: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[x-placement^="left"] {
    margin-right: 5px;
    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }
  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
  &.info {
    $color: rgba(#004499, 0.9);
    .tooltip-inner {
      background: $color;
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }
    .tooltip-arrow {
      border-color: $color;
    }
  }
  &.popover {
    $color: #f9f9f9;
    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }
    .popover-arrow {
      border-color: $color;
    }
  }
}
</style>
