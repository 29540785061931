<template>
  <div
    ref="scrollList"
    class="sc-message-list"
    :style="{
      backgroundColor: colors.messageList.bg,
      backgroundImage: `url(${backgroundImageWidget})`,
    }"
    @scroll="handleScroll"
  >
    <Message
      v-if="messages.length === 0 && !widget.welcome"
      :message="{ created_by: showTypingIndicator, type: 'welcome' }"
      :user="profile(showTypingIndicator)"
      :colors="colors"
      :message-styling="messageStyling"
      :botName="titleHeader"
    />
    <Message
      v-if="messages.length === 0 && widget.welcome"
      :message="{
        created_by: showTypingIndicator,
        type: 'text',
        data: { text: widget.welcome },
      }"
      :user="profile(showTypingIndicator)"
      :colors="colors"
      :message-styling="messageStyling"
      :botName="titleHeader"
    />

    <Message
      v-for="(message, idx) in messages"
      :key="idx"
      :message="message"
      :user="profile(message.created_by)"
      :colors="colors"
      :message-styling="messageStyling"
      @remove="$emit('remove', message)"
      :isLastMessage="messages[messages.length - 1] == message"
    >
      <template v-slot:user-avatar="scopedProps">
        <slot
          name="user-avatar"
          :user="scopedProps.user"
          :message="scopedProps.message"
        >
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot
          name="text-message-body"
          :message="scopedProps.message"
          :messageText="scopedProps.messageText"
          :messageColors="scopedProps.messageColors"
          :me="scopedProps.me"
        >
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message"> </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot
          name="text-message-toolbox"
          :message="scopedProps.message"
          :me="scopedProps.me"
        >
        </slot>
      </template>
    </Message>
    <Message
      :message="{ created_by: showTypingIndicator, type: 'live' }"
      :user="profile(showTypingIndicator)"
      :colors="colors"
      :message-styling="messageStyling"
      @updated="scrollToBottomToLive"
    />
    <Message
      v-if="isTiping"
      :message="{ created_by: showTypingIndicator, type: 'typing' }"
      :user="profile(showTypingIndicator)"
      :colors="colors"
      :message-styling="messageStyling"
    />
  </div>
</template>

<script>
import Message from "./Message.vue";
import chatIcon from "!!url-loader!@/assets/logo.png";
import { mapGetters } from "vuex";

export default {
  name: "MessageList",
  components: {
    Message,
  },
  data() {
    return {
      isTiping: false,
    };
  },
  props: {
    participants: {
      type: Array,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
    showTypingIndicator: {
      type: String,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true,
    },
    messageStyling: {
      type: Boolean,
      required: true,
    },
    titleHeader: {
      type: String,
      required: false,
      default: "",
    },
    backgroundImageWidget: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ widget: "widgetModule/widget" }),
    defaultChatIcon() {
      return chatIcon;
    },
  },
  mounted() {
    this.$nextTick(this._scrollDown());
  },
  updated() {
    if (this.shouldScrollToBottom()) {
      this.$nextTick(this._scrollDown());
      if (
        this.messages.length &&
        this.messages[this.messages.length - 1].is_user
      ) {
        setTimeout(() => {
          if (
            this.messages.length &&
            this.messages[this.messages.length - 1].is_user
          ) {
            this.isTiping = true;
          }
        }, 750);

        // this.isTiping = true;
      } else {
        this.isTiping = false;
      }

      // setTimeout(() => {
      //   this.$nextTick(this._scrollDown());
      // }, 1000);
    }
  },
  methods: {
    scrollToBottomToLive() {
      if (this.shouldScrollToBottom()) {
        this.$nextTick(this._scrollDown());
      }
    },
    _scrollDown() {
      this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight;
    },
    handleScroll(e) {
      if (e.target.scrollTop === 0) {
        this.$emit("scrollToTop");
      }
    },
    shouldScrollToBottom() {
      const scrollTop = this.$refs.scrollList.scrollTop;
      const scrollable = scrollTop > this.$refs.scrollList.scrollHeight - 600;
      return this.alwaysScrollToBottom || scrollable;
    },
    profile(created_by) {
      const profile = this.participants.find(
        (profile) => profile.id === created_by
      );

      // A profile may not be found for system messages or messages by 'me'
      return profile || { imageUrl: "", name: "" };
    },
  },
};
</script>

<style scoped>
.sc-message-list {
  height: 80%;
  overflow-x: hidden;

  overflow-y: auto;
  background-size: 100%;
  /* padding: 40px 20px; */
  padding: 10px 8px;
}
</style>
