<template>
  <div class="sc-message--text" :style="messageColors">
    <template>
      <div
        class="sc-message--toolbox"
        :style="{ background: messageColors.backgroundColor }"
      >
        <button
          v-if="showEdition && me && message.id"
          :disabled="isEditing"
          @click="edit"
        >
          <IconBase
            :color="isEditing ? 'black' : messageColors.color"
            width="10"
            icon-name="edit"
          >
            <IconEdit />
          </IconBase>
        </button>
        <button
          v-if="showDeletion && me && message.id"
          @click="$emit('remove')"
        >
          <IconBase :color="messageColors.color" width="10" icon-name="remove">
            <IconCross />
          </IconBase>
        </button>
        <slot name="text-message-toolbox" :message="message" :me="me"> </slot>

        <!-- <button class="sc-suggestions-element" style="background-color: #f5f5f5; border: 1px solid #e0e0e0; border-radius: 4px; box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); color: #333; cursor: pointer; display: inline-block; font-size: 12px; font-weight: 400; line-height: 1.42857143; margin-bottom: 0; padding: 6px 12px; text-align: center; vertical-align: middle; white-space: nowrap;" onclick="window.open('https://newsroom.porsche.com/es<u>ES/producto/2022/es-porsche-taycan-sport-turismo-gts-turbo-4s-27065.html', '</u>blank')">Nuevo Taycan Sport T</button> -->
      </div>
    </template>

    <!-- <slot
      :message="message"
      :messageText="messageText"
      :messageColors="messageColors"
      :me="me"
      :links="links"
    >
      
      <p class="sc-message--text-content" v-html="messageText"></p>
      <p
        v-if="message.data.meta"
        class="sc-message--meta"
        :style="{ color: messageColors.color }"
      >
        {{ message.data.meta }}
      </p>

   
      <p v-if="message.isEdited" class="sc-message--edited">
        <IconBase width="10" icon-name="edited">
          <IconEdit />
        </IconBase>
        edited
      </p>
    </slot> -->
    <VueMarkdown
      v-if="messageText"
      class="sc-message--text-content"
      :source="messageText"
    />
    <p
      v-else
      class="sc-message--text-content"
      :style="{ color: messageColors.color }"
    >
      {{
        $store.state.messages
          ? $store.state.messages.error
            ? $store.state.messages.error.text
              ? $store.state.messages.error.text
              : "Lo siento, no he entendido. ¿Podrías reformular tu pregunta?"
            : "Lo siento, no he entendido. ¿Podrías reformular tu pregunta?"
          : "Lo siento, no he entendido. ¿Podrías reformular tu pregunta?"
      }}
    </p>
    <div class="sc-links row">
      <!-- on hover show link url-->
      <button
        v-for="(link, index) in links"
        :key="index"
        @click="openLink(link.from, '_blank')"
        class="sc-link-button"
        :class="index == 0 || showMoreLinks ? '' : 'hidden'"
      >
        {{ link.title }}
      </button>

      <button
        v-if="links.length > 1 && !showMoreLinks"
        @click="showMoreLinks = true"
        class="sc-more-links"
      >
        +{{ links.length - 1 }}
      </button>
    </div>

    <div
      v-if="message.scored"
      style="position: relative; align-self: flex-end; text-align: right"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        v-if="message.scored == 'thumbs_up'"
        :style="{ color: primaryColor }"
        style="border: 2px solid; border-radius: 50%; padding: 4px"
        viewBox="0 0 24 24"
        fill="none"
        :stroke="primaryColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-thumbs-up button_calificacion"
      >
        <path
          d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"
        ></path>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        v-if="message.scored == 'thumbs_down'"
        :style="{ color: primaryColor }"
        style="border: 2px solid; border-radius: 50%; padding: 4px"
        viewBox="0 0 24 24"
        fill="none"
        :stroke="primaryColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-thumbs-down button_calificacion"
      >
        <path
          d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconBase from "./../components/IconBase.vue";
import IconEdit from "./../components/icons/IconEdit.vue";
import IconCross from "./../components/icons/IconCross.vue";
// import escapeGoat from "escape-goat";
// import Autolinker from "autolinker";
import store from "../store/";
import VueMarkdown from "vue-markdown";

// const fmt = require("msgdown");

export default {
  name: "TextMessage",
  components: {
    IconBase,
    IconCross,
    IconEdit,
    VueMarkdown,
  },
  data() {
    return {
      showMoreLinks: false,
    };
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    messageColors: {
      type: Object,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
    messageStyling: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    tracking() {
      return this.$store.state.widgetModule.widget.tracking;
    },
    messageText() {
      // const escaped = escapeGoat.htmlEscape([this.message.data.text]);
      // const defaultTokens = {
      //   // bold: { delimiter: "*", tag: "strong" },
      //   // italic: { delimiter: "|", tag: "em" },
      //   // underline: { delimiter: "_", tag: "u" },
      //   // strike: { delimiter: "~", tag: "del" },
      //   // code: { delimiter: "`", tag: "code" },
      //   // sup: { delimiter: "^_^", tag: "sup" },
      //   // sub: { delimiter: "¡_¡", tag: "sub" },
      // };
      // const message = Autolinker.link(escaped, {
      //   className: "chatLink",
      //   truncate: { length: 50, location: "smart" },
      // });

      // return this.messageStyling ? fmt(message, defaultTokens) : message;
      return this.message.data.text;
    },
    buttons() {
      return this.message.data.buttons;
    },
    links() {
      let links = this.message.data.links || [];
      if (links) {
        links.forEach((link) => {
          console.log(link.from);
          link.from = this.addTracking(link.from);
        });
      }
      return links || [];
    },
    me() {
      return !this.message.created_by;
    },

    isEditing() {
      return (
        (store.state.editMessage && store.state.editMessage.id) ===
        this.message.id
      );
    },
    ...mapState(["showDeletion", "showEdition"]),
  },
  methods: {
    edit() {
      store.commit("editMessage", this.message);
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    addTracking(from) {
      if (this.tracking) {
        if (from.includes("?")) return `${from}&${this.tracking}`;
        else return `${from}?${this.tracking}`;
      }
      return from;
    },
  },
};
</script>

<style scoped lang="scss">
.sc-message--text {
  box-shadow: 0 4px 16px 0 #0000001a;
  font-size: 14px;
  line-height: 1.3;
  position: relative;
  border-radius: 20px 20px 20px 0;
  word-wrap: break-word;
  // white-space: pre-wrap;
  max-width: 80%;
  padding: 1px 15px;
  -webkit-font-smoothing: subpixel-antialiased;

  .sc-message--text-content {
    // white-space: pre-wrap;
  }
  &:hover .sc-message--toolbox {
    left: -20px;
    opacity: 1;
  }
  .sc-message--toolbox {
    transition: left 0.2s ease-out 0s;
    white-space: normal;
    opacity: 0;
    position: absolute;
    left: 0px;
    width: 25px;
    top: 0;
    button {
      background: none;
      border: none;
      padding: 0px;
      margin: 0px;
      outline: none;
      width: 100%;
      text-align: center;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    ::deep svg {
      margin-left: 5px;
    }
  }
  code {
    font-family: "Courier New", Courier, monospace !important;
  }
}
.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #4e8cff;
  max-width: calc(100% - 120px);
  word-wrap: break-word;
  border-radius: 20px 20px 0 20px;
}
.sc-message--text-content a {
  font-weight: 600;
  color: black;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
  margin-right: 40px;
}

a.chatLink {
  color: inherit !important;
}
.sc-suggestions-row {
  /* display: inline-grid; */
  width: 100%;
  text-align: center;
  background: inherit;
}

.sc-suggestions-element {
  margin: 3px;
  width: fit-content;
  /* justify-self: right; */

  padding: 5px 10px 5px 10px;
  border: 1px solid;
  border-radius: 15px;
  font-size: 14px;
  background: inherit;
  cursor: pointer;
}

// more links button
// must be sutil to not disturb the user and not be too intrusive but visible in a button over border bottom of the message
.sc-more-links {
  position: absolute;
  right: -7px;
  bottom: -9px;
  margin: 0;
  padding: 0;
  /* border: none; */
  background: white;
  border-radius: 50%;
  font-size: 12px;
  color: #999;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

  &:hover {
    color: #333;
    border-color: #adadad;
  }
}

.sc-link-button {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  color: #333;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  margin: 3px;
  width: -webkit-fill-available;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sc-link-button:hover {
  border-color: #adadad;
  color: #333;
}

.sc-link-row {
  width: --webkit-fill-available;
  text-align: center;
  background: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.hidden {
  display: none;
}

.vue-markdown {
  color: red;
}
</style>
